require('./emotion_info_card.scss')
// require('../../assets/style/module/_animation.scss')

import * as d3 from 'd3'

export default class EmotionInfoCard {
  constructor (el, params) {
    this.maxWidth = 100;
    if (params.size == null || params.size === 'm') {
      this.maxWidth = 100;
    } else if (params.size === 's') {
      this.maxWidth = 70;
    }
    // 預設最大顯示數量
    this.defaultShowLength = 5;

    this.titleSet = params.titleSet

    let html = '\
      <div class="choose__emotion-list__card choose--positive">\
        <div class="choose__emotion-list__card-title"></div>\
        <div class="choose__emotion-list__card-content"></div>\
      </div>\
      <div class="choose__emotion-list__card choose--negative">\
        <div class="choose__emotion-list__card-title"></div>\
        <div class="choose__emotion-list__card-content"></div>\
      </div>\
    '
    this.componentEl = el
      .append('div')
      .classed('choose__emotion-list', true)
      .html(html)

    this.isPositiveListOpen = false
    this.isNegativeListOpen = false

    
    
  }

  _appendListTitle (el, emotion, data) {
    let count = data[emotion].count

    el.html(
      '\
      <div class="number-blk">\
        <i class="icon-' +
        emotion + '"></i>\
        <p class="title">' +
        // data[emotion].count +
        '</p>\
        <h6>則</h6>\
      </div>\
      <div class="text">' +
        this.titleSet[emotion] +
      "</div>"
    );

    let titleEl = el.select('.title')

    titleEl.transition()
      .duration(800)
      .tween('move', (d,i,all) => {
        return (t) => {
          let n = (count * t).toFixed(0)
          titleEl.text(n)
        }
      })

    // 讚和不讚動畫
    this.componentEl
      .selectAll(".choose__emotion-list__card .title")
      .on('mouseover', (d, i, all) => {
        let iEl = d3.select(all[i]).selectAll('.title .number-blk i')
        iEl.classed("rotateShake", false);
        setTimeout(() => {
          iEl.classed("rotateShake", true);
        }, 100)
        
      })
  }

  _appendListContent (el, emotion, data) {
    // el.html(
    //   '\
    //   <h6>則</h6>\
    //   <ul></ul>\
    //   <div class="more-btn">\
    //     <a class="open">\
    //       <p>展開更多詞彙</p>\
    //       <i class="icon-dropdown"></i>\
    //     </a>\
    //     <a class="close">\
    //       <p>顯示較少詞彙</p>\
    //       <i class="icon-dropdown"></i>\
    //     </a>\
    //   </div>\
    // '
    // );
    el.html(
      '\
      <ul></ul>\
      <h6>則</h6>\
    '
    );

    let listHtml = "";
    if (data[emotion].list.length > 0) {
      let startCount = data[emotion].list[0].count;
      for (let i = 0; i < data[emotion].list.length; i++) {
        let width = (data[emotion].list[i].count / startCount) * this.maxWidth;
        listHtml +=
          "\
          <li>\
            <p>" +
          data[emotion].list[i].word +
          '</p>\
            <div class="line" style="width:' +
          width +
          'px"></div>\
            <div class="count">' +
          data[emotion].list[i].count +
          "</div>\
          </li>\
        ";
      }
    }

    el.select("ul").html(listHtml);

    let li = el.selectAll("ul li");
    this._setItemShow(li, this.defaultShowLength)

    // el.on('click', () => {
    //   if (this.isPositiveListOpen === true) {
    //     this._setItemShow(li, this.defaultShowLength)
    //   } else {
    //     this._setItemShow(li, data[emotion].list.length)
    //   }
    //   this.isPositiveListOpen = !this.isPositiveListOpen
    // })

    // 文章數量手指icon
    let tarIcon = this.componentEl.selectAll(
      " .list .title .number-blk i"
    );
    tarIcon.classed("rotateShake", true);
  }

  _setItemShow (el, showLength) {
    el.style('display', 'none')
      .filter((d, i) => i < showLength)
      .style('display', null)
  }

  render (data) {
    let positiveTitleEl = this.componentEl.select(".choose__emotion-list__card.choose--positive .choose__emotion-list__card-title")
    let negativeTitleEl = this.componentEl.select(".choose__emotion-list__card.choose--negative .choose__emotion-list__card-title")
    let positiveContentEl = this.componentEl.select(".choose__emotion-list__card.choose--positive .choose__emotion-list__card-content")
    let negativeContentEl = this.componentEl.select(".choose__emotion-list__card.choose--negative .choose__emotion-list__card-content")

    this._appendListTitle(positiveTitleEl, "positive", data)
    this._appendListTitle(negativeTitleEl, "negative", data)
    this._appendListContent(positiveContentEl, "positive", data);
    this._appendListContent(negativeContentEl, "negative", data);

    // moreBtnSet();


    

    /*
    function moreBtnSet() {
      let moreBtn = this.componentEl.select(".choose__emotion-list .list .content .more-btn");

      for (let i = 0; i < moreBtn.length; i++) {
        let li = moreBtn
          .eq(i)
          .parent()
          .find("ul li");
        if (li.length < this.defaultShowLength + 1) {
          moreBtn.eq(i).style('display', 'none');
        }
      }

      moreBtn.off();
      moreBtn.click(function(event) {
        let li = $(this)
          .parent()
          .find("ul li");

        if ($(this).hasClass("active")) {
          li.style('display', 'none');
          for (let i = 0; i < this.defaultShowLength; i++) {
            li.eq(i).style('display', false);
          }
          $(this).removeClass("active");
        } else {
          li.style('display', false);
          $(this).addClass("active");
        }
      });
    }
    */
  }
}
