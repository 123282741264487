// @ts-nocheck
// 假資料
import '../../mock/fanpageSocialReactionMock.js'

require('./fanpage_social_reaction.scss')

import * as d3 from 'd3'
import * as codes from '../../utils/codes.js'
import utils from '../../utils/utils.js'
import EmotionItemList from '../../components/EmotionItemList/EmotionItemList.js'
import Tooltip from '../../components/Tooltip/Tooltip'
import ChartCard from '../../components/ChartCard/ChartCard'
import ScoreStars from '../../components/ScoreStars/ScoreStars.js'
import ReactionBarChart from '../../components/ReactionBarChart/ReactionBarChart.js'
import EmotionPercentageBar from '../../components/EmotionPercentageBar/EmotionPercentageBar.js'
import EmotionPercentageBarList from '../../components/EmotionPercentageBarList/EmotionPercentageBarList.js'
import chooseLogoSvgBase64 from '../../assets/base64/chooseLogoSvgBase64.js'

// 整張圖表都會用到的設定值（會傳入component內）
// const chartConfig = {
//   cardId: (() => {
//     // 亂數長度為5的英文+數字
//     return 'choose__fanpage-social-reaction__' + Math.random().toString(36).substr(2, 5)
//   })(),
//   colorScale (name) {
//     let scale = d3.scaleOrdinal()
//       .domain(['negative', 'neutral', 'positive'])
//       .range(['#de6867', '#ffca72', '#abd0ad'])
//     return scale(name)
//   }
// }

const apiUrl = '/api/test-fanpage-social-reaction/'
// const defaultQuery = {
//   'basic': {
//     'ignore_keyword': '1',
//     'keyword': '無主題',
//     'startDate':'2019-05-28 11:30',
//     'endDate':'2019-05-29 11:30'
//   },
//   'filter': null
// }

export default class FanpageSocialReaction {
  private dom: d3.Selection<d3.BaseType, any, any, any>
  private query: any // @Q@ 型別之後再補
  private chartData: any // @Q@ 型別之後再補
  private tooltip: Tooltip
  private chartCard: ChartCard
  private chartSelector: d3.Selection<d3.BaseType, any, any, any>
  private itemListSelector: d3.Selection<d3.BaseType, any, any, any>
  private scoreStarsEl: d3.Selection<d3.BaseType, any, any, any>
  private influentialPostEl: d3.Selection<d3.BaseType, any, any, any>
  private emotionPercentageBarListEl: d3.Selection<d3.BaseType, any, any, any>
  private commentCountEl: d3.Selection<d3.BaseType, any, any, any>
  private emotionPercentageBarEl: d3.Selection<d3.BaseType, any, any, any>
  private reactionCountEl: d3.Selection<d3.BaseType, any, any, any>
  private reactionBarChartEl: d3.Selection<d3.BaseType, any, any, any>
  private shareCountEl: d3.Selection<d3.BaseType, any, any, any>
  private emotionPercentageBarList: EmotionPercentageBarList
  private emotionPercentageBar: EmotionPercentageBar
  private reactionBarChart: ReactionBarChart

  constructor (dom, options) {
    if (!dom || !options) {
      throw new Error('missing necessary parameter');
    }
    this.dom = dom
    this.query = null
    this.chartData
    this.tooltip
    let cardSize = options.size == null ? utils.clone(codes.FANPAGE_SOCIAL_REACTION_CARD_SIZE[codes.DEFAULT_CARD_SIZE_TYPE]) : codes.FANPAGE_SOCIAL_REACTION_CARD_SIZE[options.size]
    // 放置卡片
    this.chartCard = new ChartCard(
      d3.select(dom), {
        name: 'fanpage-social-reaction',
        title: '粉絲專頁即時社群情緒指數',
        watermarkBase64: chooseLogoSvgBase64,
        tooltip: '本圖表會依據您所監測的 FB <br>粉絲專頁，根據粉絲專頁中的<br>社群互動數據，進行 7 日內即<br>時分析。',
        // style: {
        //   width: cardSize.width,
        //   height: cardSize.height,
        //   header: {
        //     height: 110
        //   }
        // }
        size: options.size || codes.DEFAULT_CARD_SIZE_TYPE,
        style: cardSize
      }
    )
    this.chartSelector = this.chartCard.chartSelector()
    this.itemListSelector = this.chartCard.itemListSelector()
    
    // 項目名稱
    new EmotionItemList(this.itemListSelector)

    // 點擊開啟蛛思
    const stopBackgroundLink = options.stopBackgroundLink == null ? false : options.stopBackgroundLink
    this.chartCard.onCardClick(() => {
      if (stopBackgroundLink === true) {
        return
      }
      utils.goToChoose()
    })
    this.chartCard.onChartClick(() => {
      if (stopBackgroundLink === true) {
        return
      }
      utils.goToChoose()
    })

    // 放置內容元素
    this.chartSelector.html(
      `<div class="choose__fanpage-social-reaction__body">
        <div class="choose__fanpage-social-reaction__col choose__col-left">
          <div class="choose__fanpage-social-reaction__row">
            <div class="choose__fanpage-social-reaction__title-box choose--normal">
              <div class="choose__fanpage-social-reaction__title">
                影響力貼文
              </div>
              <div id="choose__score_stars" class="choose__fanpage-social-reaction__subtitle">
              </div>
            </div>
            <div>
              <a id="choose__influential-post"></a>
            </div>
          </div>
          <div class="choose__fanpage-social-reaction__row">
            <div class="choose__fanpage-social-reaction__title-box choose--normal">
              <div class="choose__fanpage-social-reaction__title">
                活躍參與者
              </div>
            </div>
            <div id="choose__emotion_percentage_bar_list" class="choose__fanpage-social-reaction__chart">
            </div>
          </div>
        </div>
        <div class="choose__fanpage-social-reaction__col choose__col-right">
          <div class="choose__fanpage-social-reaction__row">
            <div class="choose__fanpage-social-reaction__title-box choose--normal">
              <div class="choose__fanpage-social-reaction__title">
                留言數
              </div>
              <div id="choose__comment_count" class="choose__fanpage-social-reaction__subtitle">
              </div>
            </div>
            <div id="choose__emotion_percentage_bar" class="choose__fanpage-social-reaction__chart">
            </div>
          </div>
          <div class="choose__fanpage-social-reaction__row">
            <div class="choose__fanpage-social-reaction__title-box choose--normal">
              <div class="choose__fanpage-social-reaction__title">
                互動數
              </div>
              <div id="choose__reaction_count" class="choose__fanpage-social-reaction__subtitle">
              </div>
            </div>
            <div id="choose__reaction_bar_chart" class="choose__fanpage-social-reaction__chart">
            </div>
          </div>
          <div class="choose__fanpage-social-reaction__row">
            <div class="choose__fanpage-social-reaction__title-box choose--short">
              <div class="choose__fanpage-social-reaction__title">
                分享數
              </div>
            </div>
            <div class="choose__fanpage-social-reaction__share-text">
              <span id="choose__share_count" class="choose__count">
              </span>
              <span class="choose__unit">
                次
              </span>
            </div>
          </div>
        </div>
      </div>`
    )

    // -- element selector --

    // 影響力星星
    this.scoreStarsEl = this.chartSelector.select('#choose__score_stars')
    // 影響力貼文
    this.influentialPostEl = this.chartSelector.select('#choose__influential-post')
    // 活躍參與者
    this.emotionPercentageBarListEl = this.chartSelector.select('#choose__emotion_percentage_bar_list')
    // 留言數
    this.commentCountEl = this.chartSelector.select('#choose__comment_count')
    // 留言數圖表
    this.emotionPercentageBarEl = this.chartSelector.select('#choose__emotion_percentage_bar')
    // 互動數
    this.reactionCountEl = this.chartSelector.select('#choose__reaction_count')
    // 互動數圖表
    this.reactionBarChartEl = this.chartSelector.select('#choose__reaction_bar_chart')
    // 分享數
    this.shareCountEl = this.chartSelector.select('#choose__share_count')

    // -- 初始化圖表 --
    // let cardSize = this.chartCard.getContainerSize()
    // 活躍參與者
    this.emotionPercentageBarList = new EmotionPercentageBarList(this.emotionPercentageBarListEl, {
      options: {
        width: ((cardSize.width / 2) - 30),
        height: 115
      }
    })
    // 留言數圖表
    this.emotionPercentageBar = new EmotionPercentageBar(this.emotionPercentageBarEl, {
      options: {
        width: ((cardSize.width / 2) - 10),
        height: 30
      }
    })
    // 互動數圖表
    this.reactionBarChart = new ReactionBarChart(this.reactionBarChartEl, {
      options: {
        width: ((cardSize.width / 2) - 10),
        height: 120
      }
    })

    // let scoreStars = new ScoreStars(this.chartSelector, 3)
    
    // this.reactionBarChart = new ReactionBarChart(this.chartSelector, chartParams)
    // this.emotionPercentageBar = new EmotionPercentageBar(this.chartSelector, chartParams)
    // this.emotionPercentageBarList = new EmotionPercentageBarList(this.chartSelector, chartParams)

  }

  
  _doRenderText () {
    // -- 說明文字 --
    let total = this.chartData.totalArticles
    let html = '您所監測的粉絲專頁為<span class="highlight">' + this.query.basic.keyword + '</span><br>\
      在<span class="highlight">' + this.query.basic.startDate + ' ~ ' + this.query.basic.endDate + '</span>這段時間\
      ' + utils.toCurrency(total) + '篇資料分析結果如下：'
    this.chartCard.setSubtitle(html)

    // -- 塞入文字及圖表 --
    // 影響力星星
    this.scoreStarsEl.html('')
    let scoreStars = new ScoreStars(this.scoreStarsEl, this.chartData.influentialPost.score)
    // 影響力貼文
    this.influentialPostEl
      .attr('href', this.chartData.influentialPost.url)
      .text(this.chartData.influentialPost.text)
      .on('mouseover', () => {
        let x = d3.event.clientX + 20
        let y = d3.event.clientY
        this.tooltip = new Tooltip(this.influentialPostEl, '觀看完整貼文', x, y)
      })
      .on('mousemove', d => {
        if(this.tooltip != null) {
          let x = d3.event.clientX + 20
          let y = d3.event.clientY
          this.tooltip.move(x, y)
        }
      })
      .on('mouseout', d => {
        if(this.tooltip != null) {
          this.tooltip.remove()
        }
      })
    // 活躍參與者
    this.emotionPercentageBarList.render(this.chartData.topUsers)
    // 留言數
    let totalCommentCount = this.chartData.commentCount.positive + this.chartData.commentCount.neutral + this.chartData.commentCount.negative
    this.commentCountEl.text(utils.toCurrency(totalCommentCount))
    // 留言數圖表
    this.emotionPercentageBar.render(this.chartData.commentCount)
    // 互動數
    let totalReactionCount = this.chartData.reaction.reduce((result, item) => {
      return result + item.value
    }, 0)
    this.reactionCountEl.text(utils.toCurrency(totalReactionCount))
    
  }

  _doRenderChart () {
    // 互動數圖表
    this.reactionBarChart.render(this.chartData.reaction)
    // 分享數
    // this.shareCountEl.text(utils.toCurrency(this.chartData.share || 0))
    this.shareCountEl.transition()
      .duration(800)
      .tween('move', (d, i, nodes) => {
        return (t) => {
          let n = utils.toCurrency((this.chartData.share * t).toFixed(0))
          d3.select(nodes[i]).text(n)
        }
      })
  }

  // 取得api資料
  fetchData (query = {}) {
    const defaultDateTimeRange = utils.get24HourDateTimeRange()
    let successCallback = function(){}
    let errorCallback = function(){}
    this.query = {
      basic: {
        // startDate: (query.startDate == null || query.endDate == null) ? defaultDateTimeRange.startDate : query.startDate,
        // endDate: (query.startDate == null || query.endDate == null) ? defaultDateTimeRange.endDate : query.endDate,
        startDate: defaultDateTimeRange.startDate,
        endDate: defaultDateTimeRange.endDate,
        keyword: query.keyword == null ? '' : query.keyword,
        'ignore_keyword': !query.keyword ? '1' : '0'
      },
      token: query.token,
      widgetLog: {
        referrer: utils.getPageHref()
      }
    }

    this.then = callback => {
      successCallback = callback
      return this
    }
    this.error = callback => {
      errorCallback = callback
      return this
    }

    setTimeout(() => {
      utils.ajax({
        type: 'POST',
        url: apiUrl,
        data: this.query,
        success (data) {
          successCallback(data)
        },
        error (data) {
          errorCallback(data)
        }
      })
    })

    return this
  }

  render (data) {
    // 將資料記住
    this.chartData = data

    // 先顯示文字
    this._doRenderText()

    // 觸發顯示圖表動畫
    utils.addScrollTrigger(this.dom, () => this._doRenderChart())

    return this
  }
}
