// @ts-nocheck
import * as d3 from 'd3'
import * as codes from '../../utils/codes.js'
import utils from '../../utils/utils.js'
import ChartCard from '../../components/ChartCard/ChartCard'
import DagreDirectedChart from '../../components/DagreDirectedChart/DagreDirectedChart'
import dbsLogoSvgBase64 from '../../assets/base64/dbsLogoSvgBase64.js'
import * as CompanyStockRelationshipTypes from '../../types/charts/companyStockRelationship'
import * as DagreDirectedChartTypes from '../../types/components/dagreDirectedChart'
// import * as screenShot from '../../utils/screenShot.js'

function hasNode (nodes, itemUniID, itemName?): boolean {
  return nodes.some(d => {
    return d.id === itemUniID || (itemName != null && d.id === itemName)
  })
}

function hasEdge (edges, start, end): boolean {
  return edges.some(d => d.start === start && d.end === end)
}

function returnTooltipText (shareholders: Array<CompanyStockRelationshipTypes.CompanyStockRelationshipRenderShareholders>): string {
  return shareholders
    .map(d => {
      let bracketText = ''
      if (d.ROL) {
        bracketText += d.ROL
      }
      if (d.title) {
        if (bracketText) {
          bracketText += '，'
        }
        bracketText += d.title
      }
      if (d.stocks) {
        if (bracketText) {
          bracketText += '，'
        }
        bracketText += `持股 ${utils.toCurrency(d.stocks)}`
      }
      return `${d.name}（${bracketText}）`
    })
    .join('</br>')
}

export default class CompanyStockRelationship implements CompanyStockRelationshipTypes.ICompanyStockRelationship {
  private dom: d3.Selection<d3.BaseType, any, any, any>
  // private query: any // @Q@ 型別之後再補
  // private chartType: string
  // private chartData: any // @Q@ 型別之後再補
  // private dataSet: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderData = {
  //   origin: { uniID: '', company_name: '' },
  //   top: {},
  //   topPerson: {},
  //   down: {}
  // }
  private rootID: string // 根節點id
  private allNodes: Array<DagreDirectedChartTypes.DagreDirectedChartNode> = []
  private allEdges: Array<DagreDirectedChartTypes.DagreDirectedChartEdge> = []
  // private center: boolean = true
  private chartCard: ChartCard
  private chartSelector: d3.Selection<d3.BaseType, any, any, any>
  private dagreDirectedChart: DagreDirectedChart
  private nodeClassNames: DagreDirectedChartTypes.DagreDirectedChartNodeClass
  // private nodeClickCallback: (arg: any) => void
  // 切換截圖模式前的尺寸
  private cardOriginSize: { width: string; height: string } = { width: '', height: '' }
  // 卡片樣式
  private cardStyle: any

  constructor (dom, options) {
    if (!dom || !options) {
      throw new Error('missing necessary parameter');
    }
    this.dom = dom
    
    // -- 放置卡片 --
    const el = d3.select(dom)
    this.cardStyle = options.size == null ? utils.clone(codes.COMPANY_STOCK_RELATIONSHIP_CARD_SIZE[codes.DEFAULT_CARD_SIZE_TYPE]) : codes.COMPANY_STOCK_RELATIONSHIP_CARD_SIZE[options.size]
    this.chartCard = new ChartCard(
      el,
      {
        name: 'company-stock-relationship',
        title: options.title,
        subtitle: '股權百分比為持有股份/(實收資本額/10)*100%',
        watermarkBase64: '',
        logoBase64: dbsLogoSvgBase64,
        tooltip: `股權結構關係圖為一張展開式的樹狀結構圖表<br>
                  ，以所查詢之公司做為中心進行運算，方向向<br>
                  上為公司的【法人】董監事、股東(如有資訊)<br>
                  ，方向向下為公司的投資對象(即公司擔任其<br>
                    他單位的法人董事)`,
        size: options.size || codes.DEFAULT_CARD_SIZE_TYPE,
        style: this.cardStyle
      }
    )
    this.chartSelector = this.chartCard.chartSelector()
    this.cardOriginSize = {
      width: el.style('width'),
      height: el.style('height')
    }

    // -- 放入圖表 --
    // const cardContainerHtml = '<div id="bpchart__company-stock-relationship" style="width:100%;height:100%"></div>'
    // this.chartSelector.html(cardContainerHtml)
    // const chartEl = this.chartSelector.select('#bpchart__company-stock-relationship')
    // const containerSize = this.chartCard.getContainerSize()
    this.dagreDirectedChart = new DagreDirectedChart(this.chartSelector, {})
    //   width: containerSize.width,
    //   height: containerSize.height
    // })

    this.nodeClassNames = this.dagreDirectedChart.getNodeClass()
    
  }

  private doInit (dataSet: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderData) {
    // 將資料加入到nodes及edges
    let newData = this.parseNodesData(dataSet, this.allNodes, this.allEdges)
    // 紀錄資料
    this.allNodes = newData.allNodes
    this.allEdges = newData.allEdges
    // console.log('doInit')
    // console.log(this.allNodes)
    // console.log(this.allEdges)
    // 重新render
    this.dagreDirectedChart.resetNodes({
      nodes: this.allNodes,
      edges: this.allEdges,
      // center: this.center
    })
    // this.dagreDirectedChart.onNodeClick((d) => {
    //   this.nodeClickCallback(d)
    // })
  }

  private addOriginData (
    originData: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderDataOrigin,
    nodes: Array<DagreDirectedChartTypes.DagreDirectedChartNode>
  ): void {
    // if (hasNode(nodes, originData.uniID, originData.company_name) === false) {
      nodes.push({
        id: originData.uniID,
        uniID: originData.uniID,
        label: originData.company_name,
        class: this.nodeClassNames.root,
        tooltip: returnTooltipText(originData.shareholders),
        hasTop: false, // 根節點不做展開資料的判斷
        isTopExpanded: false,
        source: originData
      })
    // }
  }
  
  private addTopData (
    originData: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderDataOrigin,
    topData: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderDataTop,
    nodes: Array<DagreDirectedChartTypes.DagreDirectedChartNode>,
    edges: Array<DagreDirectedChartTypes.DagreDirectedChartEdge>
  ): void {
    Object.keys(topData).forEach(d => {
      const start = topData[d].uniID || d
      const end = originData.uniID
      if (hasNode(nodes, topData[d].uniID, d) === false) {
        nodes.push({
          id: topData[d].uniID || d, // 如無uniID使用公司名稱
          uniID: topData[d].uniID || '',
          label: d,
          class: this.nodeClassNames.company,
          tooltip: returnTooltipText(topData[d].shareholders),
          hasTop: (topData[d].has_top === true || topData[d].shareholders.length) ? true : false,
          isTopExpanded: false,
          source: topData[d]
        })
      }
      if (hasEdge(edges, start, end) === false) {
        edges.push({
          start, // 如無uniID使用公司名稱
          end,
          label: topData[d].percentage != null ? `${topData[d].percentage.toFixed(2)}%` : '-'
        })
      }
    })
  }
  
  private addTopPersonData (
    originData: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderDataOrigin,
    topPersonData: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderDataTopPerson,
    nodes: Array<DagreDirectedChartTypes.DagreDirectedChartNode>,
    edges: Array<DagreDirectedChartTypes.DagreDirectedChartEdge>
  ): void {
    Object.keys(topPersonData).forEach((d, i) => {
      const id: string = `${originData.uniID}_top-person_${i}`
      const start = id
      const end = originData.uniID
      if (hasNode(nodes, id) === false) {
        nodes.push({
          id,
          uniID: '',
          label: d,
          class: this.nodeClassNames.person,
          tooltip: '', // 自然人無董兼事/股東資訊可顯示
          hasTop: false, // 自然人無上游
          isTopExpanded: false,
          source: topPersonData[d]
        })
      }
      if (hasEdge(edges, start, end) === false) {
        edges.push({
          start,
          end,
          label: topPersonData[d].percentage != null ? `${topPersonData[d].percentage.toFixed(2)}%` : '-'
        })
      }
    })
  }
  
  private addDownData (
    originData: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderDataOrigin,
    downData: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderDataDown,
    nodes: Array<DagreDirectedChartTypes.DagreDirectedChartNode>,
    edges: Array<DagreDirectedChartTypes.DagreDirectedChartEdge>
  ): void {
    Object.keys(downData).forEach(d => {
      const start = originData.uniID
      const end = downData[d].uniID || d
      nodes.push({
        id: downData[d].uniID || d, // 如無uniID使用公司名稱
        uniID: downData[d].uniID || '',
        label: d,
        class: this.nodeClassNames.company,
        tooltip: returnTooltipText(downData[d].shareholders),
        hasTop: originData.uniID !== this.rootID ? true : false, // 根節點的下層一律為false（不做展開資料的判斷），其餘的皆為有上層資料
        isTopExpanded: false,
        source: downData[d]
      })
      if (hasEdge(edges, start, end) === false) {
        edges.push({
          start,
          end, // 如無uniID使用公司名稱
          label: downData[d].percentage != null ? `${downData[d].percentage.toFixed(2)}%` : '-'
        })
      }
    })
  }
  
  private parseNodesData (
    dataSet: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderData,
    allNodes: Array<DagreDirectedChartTypes.DagreDirectedChartNode>,
    allEdges: Array<DagreDirectedChartTypes.DagreDirectedChartEdge>
  ): {
    allNodes: Array<DagreDirectedChartTypes.DagreDirectedChartNode>;
    allEdges: Array<DagreDirectedChartTypes.DagreDirectedChartEdge>;
  } {
    // 目標公司（初始化時才增加）
    if (allNodes.length < 1) {
      this.rootID = dataSet.origin.uniID // 紀錄根節點
      this.addOriginData(dataSet.origin, allNodes)
    }
    // 上游法人
    if (dataSet.top) {
      this.addTopData(dataSet.origin, dataSet.top, allNodes, allEdges)
    }
    // 上游自然人
    if (dataSet.topPerson) {
      this.addTopPersonData(dataSet.origin, dataSet.topPerson, allNodes, allEdges)
    }
    // 下游法人
    if (dataSet.down) {
      this.addDownData(dataSet.origin, dataSet.down, allNodes, allEdges)
    }
    
    return { allNodes, allEdges }
  }

  public init (dataSet: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderData): void {
    // 將資料記住
    // this.dataSet = dataSet
    // 觸發顯示圖表動畫
    utils.addScrollTrigger(this.dom, () => this.doInit(dataSet))
  }

  public setTitle (title): void {
    this.chartCard.setTitle(title)
  }

  public onNodeClick (callback): void {
    // this.nodeClickCallback = callback
    this.dagreDirectedChart.onNodeClick((d) => {
      // 禁止點擊：非公司、或為根節點、或無可展開的父節點
      if (!d.uniID || d.id === this.rootID || d.hasTop === false) {
        return
      }
      callback(d)
    })
  }

  public addData (dataSet: CompanyStockRelationshipTypes.CompanyStockRelationshipRenderData): void {
    // 將資料記住
    // this.dataSet = dataSet
    // this.center = center

    const allNodes: Array<DagreDirectedChartTypes.DagreDirectedChartNode> = JSON.parse(JSON.stringify(this.allNodes))
    const allEdges: Array<DagreDirectedChartTypes.DagreDirectedChartEdge> = JSON.parse(JSON.stringify(this.allEdges))
    // 將資料加入到nodes及edges
    const newData = this.parseNodesData(dataSet, allNodes, allEdges)
    // 紀錄資料
    this.allNodes = newData.allNodes
    this.allEdges = newData.allEdges
    // console.log('doAddNodes')
    // console.log(this.allNodes)
    // console.log(this.allEdges)
    // 重新render
    this.dagreDirectedChart.resetNodes({
      nodes: this.allNodes,
      edges: this.allEdges,
      // center: this.center
    })

    // return this
  }

  public toggleTopNodes (id: string): void {
    this.dagreDirectedChart.toggleTopNodes(id)
  }

  public async screenShotMode (isScreenShot: boolean): void {
    if (isScreenShot) {
      // 取得寬高
      const screenShotSize = this.dagreDirectedChart.getChartSize()
      // 將卡片寬高設為和符合圖表尺寸（圖表寬高加上卡片間距）
      let width = screenShotSize.width + this.cardStyle['padding-left'] + this.cardStyle['padding-right']
      let height = screenShotSize.height + this.cardStyle['padding-top'] + this.cardStyle['padding-bottom'] + this.cardStyle.header.height + this.cardStyle.footer.height + 20
      if (width < 640) {
        width = 640
      }
      if (height < 480) {
        height = 480
      }
      this.chartCard.setCardSize({
        width: `${width}px`,
        height: `${height}px`
      })
      // 置中
      this.dagreDirectedChart.resize()
    } else {
      // 將卡片寬高還原
      this.chartCard.setCardSize({
        width: this.cardOriginSize.width,
        height: this.cardOriginSize.height
      })
      // 置中
      this.dagreDirectedChart.resize()
    }

    // 截圖
    // await screenShot.downloadScreenImage(this.dom)

  }

  public resize () {
    // 置中
    this.dagreDirectedChart.resize()
  }
}