// @ts-nocheck
// 圖表最外層的通用樣式
require('../../assets/style/style.scss')
require('./chart_card.scss')
// const logoImg = require('../../assets/images/blueplanet_logo.svg')

// import { select, event } from 'd3-selection'
import utils from '../../utils/utils.js'
import Tooltip from '../Tooltip/Tooltip'
import Dropdown from '../Dropdown/Dropdown.js'
// const d3 = {
//   select,
//   event
// }
import * as d3 from 'd3'
import * as codes from '../../utils/codes.js'
// import companyLogoSvgBase64 from '../../assets/base64/companyLogoSvgBase64.js'
// import chooseLogoSvgBase64 from '../../assets/base64/chooseLogoSvgBase64.js'
import * as ChartCardTypes from '../../types/components/chartCard'

// let style = utils.clone(codes.CARD_SIZE[codes.DEFAULT_CARD_SIZE_TYPE])

function returnCardId (id: string): string {
  // 亂數長度為5的英文+數字
  return `bpchart__${id}__${Math.random().toString(36).substr(2, 5)}`
}

export default class ChartCard implements ChartCardTypes.IChartCard {
  private el: d3.Selection<d3.BaseType, any, any, any>
  private cardEl: d3.Selection<d3.BaseType, any, any, any>
  private cardId: string = ''
  private tooltip: any = null // @Q@之後再補型別
  private tooltipText: string = ''
  private cardClickCallback: () => void = function () { return null }
  private chartClickCallback: () => void = function () { return null }
  private dropdownSelectCallback: () => void = function () { return null }
  private dropdownData: any = null // @Q@之後再補型別
  private size: string = codes.DEFAULT_CARD_SIZE_TYPE
  private style: ChartCardTypes.ChartCardStyle

  constructor (el: d3.Selection<d3.BaseType, any, any, any>, props: ChartCardTypes.ChartCardProps) {
    if (!props || !props.title) {
      throw new Error('missing necessary parameter')
    }
    this.el = el
    this.cardId = returnCardId(props.name)
    this.tooltip = null
    this.tooltipText = props.tooltip || ''
    this.dropdownData = props.dropdown
    this.size = props.size || codes.DEFAULT_CARD_SIZE_TYPE
    if (codes.COMMON_CARD_SIZE[this.size]) {
      this.style = utils.clone(codes.COMMON_CARD_SIZE[this.size])
    } else {
      this.style = utils.clone(codes.COMMON_CARD_SIZE[codes.DEFAULT_CARD_SIZE_TYPE]) // default size
    }
    if (props.style) {
      if (props.style.width != null) this.style.width = props.style.width
      if (props.style.height != null) this.style.height = props.style.height
      if (props.style['padding-top'] != null) this.style['padding-top'] = props.style['padding-top']
      if (props.style['padding-right'] != null) this.style['padding-right'] = props.style['padding-right']
      if (props.style['padding-bottom'] != null) this.style['padding-bottom'] = props.style['padding-bottom']
      if (props.style['padding-left'] != null) this.style['padding-left'] = props.style['padding-left']
      if (props.style.header) {
        if (props.style.header.height != null) this.style.header.height = props.style.header.height
      }
      if (props.style.footer) {
        if (props.style.footer.height != null) this.style.footer.height = props.style.footer.height
        if (props.style.footer.logo != null) {
          if (props.style.footer.logo.width != null) this.style.footer.logo.width = props.style.footer.logo.width
          if (props.style.footer.logo.height != null) this.style.footer.logo.height = props.style.footer.logo.height
        }
      }
    }
    let cardWidth = ''
    let cardHeight = ''
    if (this.style.width === 'auto') {
      cardWidth = '100%'
    } else {
      cardWidth = `${this.style.width}px`
    }
    if (this.style.height === 'auto') {
      cardHeight = '100%'
    } else {
      cardHeight = `${this.style.height}px`
    }
    // const cardSize = this.getContainerSize()
    const html =
      `<div
        id="${this.cardId}"
        class="bpchart__root bpchart__chart-card"
        style="
          width:100%;height:100%;
          padding:${this.style['padding-top']}px ${this.style['padding-right']}px ${this.style['padding-bottom']}px ${this.style['padding-left']}px;
        ">
        <div class="bpchart__chart-card__header" style="height:${this.style.header.height}px">
          <div class="bpchart__chart-card__title-box">
            <span class="bpchart__chart-card__title">${props.title || ''}</span>
            <span id="bpchart__chart-card__tip-btn" class="bpchart__chart-card__tip-btn">[?]</span>
          </div>
          <div class="bpchart__chart-card__subtitle">${props.subtitle || ''}</div>
          <div id="bpchart__chart-card__item-list-block"></div>
          <div id="bpchart__chart-card__dropdown"></div>
        </div>
        <div
          id="bpchart__chart-card__container"
          class="bpchart__chart-card__container"
          style="width:100%;height:calc(100% - ${this.style.header.height + this.style.footer.height}px)"></div>
        <div class="bpchart__chart-card__footer" style="height:${this.style.footer.height || ""}px">
          ${props.footerHtml || ''}
        </div>
        <div style="background: url(${props.watermarkBase64 || ""}) no-repeat center;position: absolute;top: 0px;left: 0px;width: 100%;height: 100%;opacity: 0.05;pointer-events: none;"></div>
      </div>`
    
    this.setCardSize({ width: cardWidth, height: cardHeight })
    el.html(html)
    this.cardEl = this.el.select(`#${this.cardId}`)

    // 點擊卡片事件
    d3.select('#' + this.cardId)
      .on('click', () => {
        this.cardClickCallback()
        d3.event.stopPropagation()
      })
    // 點擊圖表事件
    d3.select('#' + this.cardId + ' #bpchart__chart-card__container')
      .on('click', () => {
        this.chartClickCallback()
        d3.event.stopPropagation()
      })

    // 提示tooltip事件
    const tipBtnEl = d3.select('#' + this.cardId + ' .bpchart__chart-card__tip-btn')
    if (tipBtnEl) {
      tipBtnEl.on('mouseover', (d, i, all) => {
        const content = `<div>${this.tooltipText}</div>`
        this.tooltip = new Tooltip(d3.select(all[i]), content, null, null)
      })
      tipBtnEl.on('mouseout', () => {
        this.tooltip.remove()
      })
    }
  }

  public getCardId (): string {
    return this.cardId
  }
  public setTitle (html: string): void {
    this.el.select('#' + this.cardId + ' .bpchart__chart-card__title').html(html)
  }
  public setSubtitle (html: string): void {
    this.el.select('#' + this.cardId + ' .bpchart__chart-card__subtitle').html(html)
  }
  // 取得圖表容量的寬高
  public getContainerSize (): { width: number; height: number } {
    let cardWidth = 0
    let cardHeight = 0
    // const cardDom = window.document.getElementById(this.cardId)
    // if (cardDom) {
    //   cardWidth = cardDom.clientWidth
    //   cardHeight = cardDom.clientHeight
    // }
    const card = this.el.select(`#${this.cardId}`)
    try {
      const getBoundingClientRect = card.node().getBoundingClientRect()
      cardWidth = getBoundingClientRect.width
      cardHeight = getBoundingClientRect.height
    } catch (e) { console.error(e) }
    return {
      width: cardWidth - this.style['padding-left'] - this.style['padding-right'],
      height: cardHeight - this.style['padding-top'] - this.style['padding-bottom'] - this.style.footer.height - this.style.header.height // 扣掉header和footer的高度
    }
  }
  public setCardSize ({ width, height }: { width: string | number; height: string | number }): void {
    // console.log('setCardSize')
    // console.log(this.el)
    // console.log({width, height})
    this.el.style('width', width)
    this.el.style('height', height)
  }
  public chartSelector (): d3.Selection<d3.BaseType, any, any, any> {
    // let test = this.itemListSelector()
    return this.cardEl.select('#bpchart__chart-card__container')
  }
  public itemListSelector (): d3.Selection<d3.BaseType, any, any, any> {
    return this.cardEl.select('#bpchart__chart-card__item-list-block')
  }
  public dropdownSelector (): d3.Selection<d3.BaseType, any, any, any> {
    return this.cardEl.select('#bpchart__chart-card__dropdown')
  }
  public onCardClick (callback): void {
    this.cardClickCallback = callback
  }
  public onChartClick (callback): void {
    this.chartClickCallback = callback
  }
  public onDropdownSelect (callback) {
    this.dropdownSelectCallback = callback
    // 下拉選單
    if (this.dropdownData) {
      const dropdownEl = this.cardEl.select('#bpchart__chart-card__dropdown')
        .append('div')
        .classed('bpchart__dropdown-box', true)
        .style('top', () => {
          if (this.size === 'm') {
            return '50px'
          } else if (this.size === 's') {
            return '0px'
          }
        })
      const dropdown = new Dropdown(dropdownEl, this.dropdownData.options, this.dropdownData.value)
      // 選擇事件callback
      dropdown.onChange(this.dropdownSelectCallback)
    }
  }
}
