export const DEFAULT_CARD_SIZE_TYPE = 'm'

// 預設/基本圖表卡片樣式
export const COMMON_CARD_SIZE = {
  l: { // @Q@ L暫時沒用到，隨便key的
    width: 800,
    height: 590,
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 100
    },
    footer: {
      height: 25,
      logo: {
        width: 0,
        height: 0
      }
    }
  },
  m: {
    width: 650, // total 650 // 沒設box-sizing的話為 610
    height: 480, // total 480 // 沒設box-sizing的話為 459
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 100
    },
    footer: {
      height: 25,
      logo: {
        width: 0,
        height: 0
      }
    }
  },
  s: {
    width: 560,
    height: 420,
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 100
    },
    footer: {
      height: 25,
      logo: {
        width: 0,
        height: 0
      }
    }
  }
}

export const MEDIA_EMOTION_CARD_SIZE = {
  m: {
    width: 650,
    height: 700,
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 85
    },
    footer: {
      height: 25
    }
  },
  s: {
    width: 560,
    height: 670,
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 85
    },
    footer: {
      height: 25
    }
  }
}

export const FANPAGE_SOCIAL_REACTION_CARD_SIZE = {
  m: {
    width: 650,
    height: 480,
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 100
    },
    footer: {
      height: 25
    }
  },
  s: {
    width: 560,
    height: 480,
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 100
    },
    footer: {
      height: 25
    }
  }
}

export const EMOTION_NAME = {
  positive: '正面',
  neutral: '中立',
  negative: '負面'
}

export const COMPANY_STOCK_RELATIONSHIP_CARD_SIZE = {
  auto: {
    width: 'auto',
    height: 'auto',
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 85
    },
    footer: {
      height: 25
    }
  },
  xl: {
    width: 1024,
    height: 760,
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 85
    },
    footer: {
      height: 25
    }
  },
  l: {
    width: 800,
    height: 590,
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 85
    },
    footer: {
      height: 25
    }
  },
  m: {
    width: 650, // total 650 // 沒設box-sizing的話為 610
    height: 480, // total 480 // 沒設box-sizing的話為 459
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 85
    },
    footer: {
      height: 25
    }
  },
  s: {
    width: 560,
    height: 420,
    'padding-top': 16,
    'padding-right': 20,
    'padding-bottom': 5,
    'padding-left': 20,
    header: {
      height: 85
    },
    footer: {
      height: 25
    }
  }
}
