require('./dropdown.scss')

import * as d3 from 'd3'
import triangleBase64 from '../../assets/base64/triangleSvgBase64.js'

export default class Dropdown {
  constructor (el, options, value, params) {
    if (options == null) return
    if (value == null) value = options[0].value // 預設為第一筆資料的值
    let optionsHtml = options.map(d => {
      if (d.value === value) {
        return '<option value="' + d.value + '" selected>' + d.name + '</option>'
      } else {
        return '<option value="' + d.value + '">' + d.name + '</option>'
      }
    })

    // const dropdownBoxEl = el.append('div')
    //   .classed('bpchart__dropdown-box', true)
    this.dropdownEl = el.append('select')
      .attr('id', 'bpchart__dropdown')
      .html(optionsHtml)

    // 下三角形箭頭
    el.append('img')
      .classed('bpchart__select-icon', true)
      .attr('src', triangleBase64)

    // 下拉選單事件
    this.changeCallback = function () {}
    this.dropdownEl.on('click', (d, i, all) => {
      d3.event.stopPropagation()
    })
  }

  onChange (callback) {
    this.changeCallback = callback

    this.dropdownEl.on('change', null)
    this.dropdownEl.on('change', (d, i, all) => {
      const value = d3.select(all[i]).property('value')
      this.changeCallback(value)
      d3.event.stopPropagation()
    })
  }
}
