// @ts-nocheck
// require('./dagre_directed_chart.scss')

import * as d3 from 'd3'
// import utils from '../../utils/utils.js'
import d3Utils from '../../utils/d3Utils.js'
import * as ForceDirectedChartTypes from '../../types/components/forceDirectedChart'
import Tooltip from '../Tooltip/Tooltip'

// 按鈕icon樣式
const fnIconSize = 16
const fnIconActiveSize = 26
const fnIconActiveOffset = (fnIconActiveSize - fnIconSize) / 2 // 滑鼠移過觸發的偏移

const pushpinSvg = `<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="69.654" y1="448.2119" x2="416.1364" y2="101.7296" gradientTransform="matrix(1.0449 0 0 -1.0449 2.1993 543.3086)">
<stop offset="0" style="stop-color:#FFC06B"/>
<stop offset="1" style="stop-color:#E66900"/>
</linearGradient>
<circle style="fill:url(#SVGID_3_);" cx="255.996" cy="255.996" r="255.996"/>
<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="137.2118" y1="208.5621" x2="176.5023" y2="169.2816" gradientTransform="matrix(1.0449 0 0 -1.0449 2.1993 543.3086)">
<stop offset="0" style="stop-color:#FFFFFF"/>
<stop offset="1" style="stop-color:#C8C6CC"/>
</linearGradient>
<polygon style="fill:url(#SVGID_4_);" points="216.374,254.563 95.273,416.712 257.424,295.611 "/>
<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="222.4087" y1="414.4272" x2="382.3457" y2="254.4902" gradientTransform="matrix(1.0449 0 0 -1.0449 2.1993 543.3086)">
<stop offset="0" style="stop-color:#FF0335"/>
<stop offset="1" style="stop-color:#990011"/>
</linearGradient>
<path style="fill:url(#SVGID_5_);" d="M330.534,314.016l-3.5-3.5l91.033-138.141l18.583,18.583c5.764,5.764,15.196,5.764,20.959,0  l4.368-4.368c5.764-5.764,5.764-15.195,0-20.959L346.356,50.01c-5.764-5.764-15.196-5.764-20.959,0l-4.368,4.368  c-5.764,5.764-5.764,15.196,0,20.959l18.583,18.583l-138.141,91.033l-3.501-3.5c-9.502-9.502-25.05-9.502-34.552,0l-14.846,14.846  l167.115,167.115l14.846-14.846C340.036,339.066,340.036,323.518,330.534,314.016z"/>`

function returnPushpinLocation (circleR: number): { x: number; y: number; } {
  return {
    x: circleR * 0.8,
    y: - circleR
  }
}

function returnExpandBtnLocation (circleR: number): { x: number; y: number; } {
  return {
    x: circleR,
    y: - circleR + 20
  }
}

// 新增節點遞迴（目前只展開一層所以無遞迴）
function addLoop (
  { nodes, links }: { nodes: Array<ForceDirectedChartTypes.ChartNode>; links: Array<ForceDirectedChartTypes.ChartLink>;},
  { nodes: allNodes, links: allLinks, _NodesMap }: { nodes: Array<ForceDirectedChartTypes.ChartNode>; links: Array<ForceDirectedChartTypes.ChartLink>; _NodesMap: Map<string, any>; },
  originID: string): {
    nodes: Array<ForceDirectedChartTypes.ChartNode>;
    links: Array<ForceDirectedChartTypes.ChartLink>;
  } {
  const rootID = allNodes[0].id
  // step1 新增關聯links
  let findIDs = []
  for (let i in allLinks) {
    let newNode = null
    if (allLinks[i]._target === originID) {
      newNode = allLinks[i]._source
    // } else if (allLinks[i]._source === originID) {
    //   newNode = allLinks[i]._target
    } else {
      continue
    }
    let isExist = links.some((d: ForceDirectedChartTypes.ChartLink) => d._source === allLinks[i]._source && d._target === allLinks[i]._target)
    if (isExist === true) {
      continue
    }
    let newLink = JSON.parse(JSON.stringify(allLinks[i])) // 要用深拷貝不然有bug
    links.push(newLink)
    findIDs.push(newNode)
  }
  if (findIDs.length < 1) {
    return { nodes, links }
  }
  // step2 如果source-nodes無存在目前nodes則新增進來
  let findNoneNodeIDs = []
  let addNodes: Array<ForceDirectedChartTypes.ChartNode> = []
  for (let testID of findIDs) {
    let isExist = nodes.some((d: ForceDirectedChartTypes.ChartNode) => d.id === testID)
    if (isExist === false) {
      // let findNode = allNodes.find((d: ForceDirectedChartTypes.ChartNode) => d.id === testID)
      let findNode = _NodesMap.get(testID)
      if (findNode) {
        addNodes.push(findNode)
        findNoneNodeIDs.push(findNode.id)
      }
    }
  }
  // step3 修正展開狀態
  addNodes = addNodes.map((node: ForceDirectedChartTypes.ChartNode) => {
    let hasTop = allLinks.some(link => {
      // 非node的上層
      if (link._target !== node.id) {
        return false
      }
      // 如果上層為根節點則無展開按鈕
      if (link._source === rootID) {
        return false
      }
      // 有上層且非根節點
      return true
    })
    if (hasTop) {
      node.hasTop = true
      node.isTopExpanded = false // 預設false
    }
    return node
  })
  nodes = nodes.concat(addNodes)
  // step3 用前步驟新增的nodes再往子node新增
  // for (let nextID of findNoneNodeIDs) {
  //   addLoop(nodes, links, allNodes, allLinks, nextID)
  // }
  return { nodes, links }
}
// 刪除節點遞迴
function deleteLoop (
  { nodes, links }: { nodes: Array<ForceDirectedChartTypes.ChartNode>; links: Array<ForceDirectedChartTypes.ChartLink> },
  { nodes: allNodes, links: allLinks }: { nodes: Array<ForceDirectedChartTypes.ChartNode>; links: Array<ForceDirectedChartTypes.ChartLink>; },
  originID): {
    nodes: Array<ForceDirectedChartTypes.ChartNode>;
    links: Array<ForceDirectedChartTypes.ChartLink>;
  } {
  const rootID = allNodes[0].id
  // let newData = { nodes, links }
  let newNodes = []
  let newLinks = []
  // step1 刪除關聯links
  let findIDs = []
  for (let i in links) {
    if (links[i]._target === originID && links[i]._source !== rootID) {
      // 取得點擊對象的上游（如為根節點不移除）
      findIDs.push(links[i]._source)
    // } else if (links[i]._source === originID
    // && links.some(e => e._target == links[i]._target && e._source == rootID)) {
    //   // 如點擊對象的下游是根節點的下游，取得點擊對象的下游
    //   findIDs.push(links[i]._target)
    } else {
      // 不刪除
      newLinks.push(links[i])
    }
  }
  // step2 過濾掉無連接到根節點的線
  const filterLinksFromRoot = (links) => {
    let newLinks = []
    // const searchUp = (_source:string, _target:string) => {
    //   const upLinks = links.filter(link => _source === link._target) // 上一層節點的連接線
    //   for (const link of upLinks) {
    //     let isExist = newLinks.some(newLink => {
    //       return newLink._source === link._source && newLink._target === link._target
    //     })
    //     if (isExist == false) {
    //       newLinks.push(link)
    //       searchUp(link._source, link._target)
    //     }
    //   }
    // }
    // const topFromRoot = links.filter(link => link._target === rootID)
    // for (const link of topFromRoot) {
    //   newLinks.push(link)
    //   searchUp(link._source, link._target)
    // }
    // const searchDown = (_source:string, _target:string) => {
    //   const downLinks = links.filter(link => _target === link._source) // 下一層節點的連接線
    //   for (const link of downLinks) {
    //     let isExist = newLinks.some(newLink => {
    //       return newLink._source === link._source && newLink._target === link._target
    //     })
    //     if (isExist == false) {
    //       newLinks.push(link)
    //       searchDown(link._source, link._target)
    //     }
    //   }
    // }
    // const downFromRoot = links.filter(link => link._source === rootID)
    // for (const link of downFromRoot) {
    //   let isExist = newLinks.some(newLink => {
    //     return newLink._source === link._source && newLink._target === link._target
    //   })
    //   if (isExist == false) {
    //     newLinks.push(link)
    //   }
    //   searchDown(link._source, link._target)
    // }
    const searchLink = (currentID:string) => {
      const topFromCurrent = links.filter(link => link._target === currentID)
      const downFromCurrent = links.filter(link => link._source === currentID)
      for (const link of topFromCurrent) {
        let isExist = newLinks.some(newLink => {
          return newLink._source === link._source && newLink._target === link._target
        })
        if (isExist == false) {
          newLinks.push(link)
          searchLink(link._source)
        }
      }
      for (const link of downFromCurrent) {
        let isExist = newLinks.some(newLink => {
          return newLink._source === link._source && newLink._target === link._target
        })
        if (isExist == false) {
          newLinks.push(link)
          searchLink(link._target)
        }
      }
    }
    searchLink(rootID)

    return newLinks
  }
  newLinks = filterLinksFromRoot(newLinks)
  // step3 過濾掉無連接線的節點
  newNodes = nodes.filter((node:ForceDirectedChartTypes.ChartNode) => {
    return newLinks.find((link:ForceDirectedChartTypes.ChartLink) => node.id === link._source || node.id === link._target) != null
  })

  // step2 如果source-node已無edge則刪除該node
  // let findNoneLinkIDs = []
  // for (let testID of findIDs) {
  //   let testFilter = links.find((d: ForceDirectedChartTypes.ChartLink) => {
  //     return d._source === testID // 該node為上游的連接線
  //       // || (d._source === rootID && d._target === testID) // 該node為根節點的下游
  //       || d._target === testID
  //   })
  //   if (testFilter == null) {
  //     findNoneLinkIDs.push(testID)
  //     // @Q@ 刻意讓泡泡飛走所以不移除節點，如果要移除的話就把下面這行解開
  //     // nodes = nodes.filter((d: ForceDirectedChartTypes.ChartNode) => d.id !== testID)
  //   }
  // }
  // @Q@ 後來發現原先做法無法找出所有子節點（漏掉了同時是上游也是下游的情況），所以改為刻意不移除節點的做法
  // step3 用前步驟被刪除的nodes再去往子node刪
  // for (let nextID of findNoneLinkIDs) {
  //   allNodes = allNodes.map((d: ForceDirectedChartTypes.ChartNode) => {
  //     if (d.id === nextID) {
  //       // 展開狀態關閉
  //       d.isTopExpanded = false
  //     }
  //     return d
  //   })
  //   newData = deleteLoop(nodes, links, allNodes, allLinks, nextID)
  //   nodes = newData.nodes
  //   links = newData.links
  // }
  // step3 用前步驟被刪除的nodes再去往子node刪
  
  return {
    nodes: newNodes,
    links: newLinks
  }
}

// function getExpendIconX (rectWidth) {
//   return (rectWidth / 2) + 5
// }

export default class ForceDirectedChart implements ForceDirectedChartTypes.IForceDirectedChart {
  private el: d3.Selection<any, any, any, any>
  private svg: d3.Selection<any, any, any, any>
  private svgGroup: d3.Selection<any, any, any, any>
  private defs: d3.Selection<any, any, any, any>
  // private chartG = null
  // 全部的資料（包含未展開的）
  private allData = {
    nodes: [],
    links: [],
    _NodesMap: null // 將nodes轉為Map物件
  }
  // 顯示的資料（不包含未展開的）
  private showData = {
    nodes: [],
    links: [],
    _NodesMap: null // 將nodes轉為Map物件
  }
  private nodeClickCallback: (arg: any) => void = function () { return null }
  private extandBtnClickCallback: (arg: any) => void = function () { return null }
  private tooltip: Tooltip = null
  private zoom = null

  private force
  private path: d3.Selection<any, any, any, any>
  private pathText: d3.Selection<any, any, any, any>
  private circle: d3.Selection<any, any, any, any>
  private circleText: d3.Selection<any, any, any, any>
  private circleBtn: d3.Selection<any, any, any, any>
  private pathG: d3.Selection<any, any, any, any>
  private pathTextG: d3.Selection<any, any, any, any>
  private circleG: d3.Selection<any, any, any, any>
  private circleTextG: d3.Selection<any, any, any, any>
  private circleBtnG: d3.Selection<any, any, any, any>
  private pushpin: d3.Selection<any, any, any, any>
  private countdown = 10 // force stop倒數計時秒數
  private isCountingdown = false // 是否正在倒數計時
  private noneStopMode = false // 動畫不停止模式（當需要避免動畫卡住）
  private highlightLockMode = false // 鎖定highlight模式
  private styleConfig = {
    styles: {
      circleRoot: 'stroke-width: 5px; fill: #1778F5;',
      circle: 'stroke: #909399;stroke-width: 5px; fill: #fff;',
      circleText: 'fill: #303133; font-weight: normal; ',
      pathText: 'fill: #606266; font-weight: 300; font-size: 13px; text-shadow: 0 1px 0 #fff, 1px 0 0 #fff, 0 -1px 0 #fff, -1px 0 0 #fff;pointer-events: none;',
      pathTop: 'stroke: #909399; stroke-width: 1.5px; fill:none;',
      pathDown: 'stroke-dasharray: 5; stroke: #909399; stroke-width: 1.5px; fill:none;',
      arrow: 'fill: #909399',
      circleLocked: 'stroke: #c00',
      pathLocked: 'stroke: #c00',
      arrowLocked: 'fill: #c00',
      expandBtn: 'fill:#1778F5'
    },
    arrows: ['arrow'], // 對應箭頭的styles
    circlesR: {
      circleRoot: 60,
      circle: 60
    },
    _StylesMap: null, // styles 的Map
    _CirclesRMap: null // circlesR 的Map
  }
  private plusSvgHtml = this.returnPlusSvgHtml()
  private minusSvgHtml = this.returnMinusSvgHtml()
  private rootID = ''

  constructor (el: d3.Selection<any, any, any, any>, props: ForceDirectedChartTypes.ChartProps) {
    this.el = el
    const { width, height } = this.getContainerSize()

    this.svg = el
      .append("svg")
      .attr("width", width)
      .attr("height", height);
    this.svgGroup = this.svg.append('g').classed('bp__force-directed-chart__g', true)
    this.defs = this.svgGroup.append("defs")
    
    this.pathG = this.svgGroup.append("g")
    this.circleG = this.svgGroup.append("g")
    this.pathTextG = this.svgGroup.append("g")
    this.circleTextG = this.svgGroup.append("g")
    this.circleBtnG = this.svgGroup.append("g")

    this.force = d3.forceSimulation()
      .velocityDecay(0.2)
      // .alphaDecay(0.1)
      .force(
        "link",
        d3.forceLink()
          .id((d: ForceDirectedChartTypes.ChartNode) => d.id)
          .strength(1)
          .distance((d: ForceDirectedChartTypes.ChartLink) => {
            if (d.direction === 'top') {
              return 200
            } else {
              return 250
            }
          })
      )
      .force("charge", d3.forceManyBody().strength(-2000))
      .force("collision", d3.forceCollide(60).strength(1)) // @Q@ 60為泡泡的R，暫時是先寫死的
      .force("center", d3.forceCenter(width / 2, height / 2))

      let linearGradient = this.svg
        .append('defs')
        .append('linearGradient')
        .attr('id', 'gradient')
      linearGradient
        .append('stop')
        .attr('offset', '0%')
        .style('stop-color', '#81c4c3')
        // .style('stop-color', '#2499FF')
      linearGradient
        .append('stop')
        .attr('offset', '100%')
        .style('stop-color', '#2f8cbf')
        // .style('stop-color', '#004DB1')

    // 初始化styleConfig
    this.setStyle(styleConfig => styleConfig)
  }

  // 建立箭頭
  private setArrowMarker () {
    // Per-type markers
    const update = this.defs
      .selectAll(".bp__force-directed-chart__arrow-marker")
      .data(this.styleConfig.arrows)
    const enter = update.enter()
      .append("marker")
      .classed("bp__force-directed-chart__arrow-marker", true)
      // .attr("viewBox", "0 -5 10 10")
      .attr("viewBox", "0 -5 10 10")
      // .attr("markerWidth", 6)
      // .attr("markerHeight", 6)
      .attr("markerWidth", 10)
      .attr("markerHeight", 10)
      .attr("orient", "auto")
    enter.merge(update)      
      .attr("id", type => type)
      // .attr("refX", 60 + 15) // @Q@ 60為泡泡的R，暫時是先寫死的
      .attr("refX", type => {
        return this.styleConfig._CirclesRMap.get(type) ? this.styleConfig._CirclesRMap.get(type) - 20 : 60 -20
      }) // @Q@ 我也不太確定為什麼是-20
      .attr("refY", 0)
      .attr("style", type => this.styleConfig._StylesMap.get(type))
    enter.append("path")
      .attr("d", "M0,-5L10,0L0,5");
    update.exit().remove()
  }

  private returnPlusSvgHtml (style='fill:#1778f5') {
    return `
    <circle style="${style};" cx="255.996" cy="255.996" r="255.996"/>
    <polygon style="fill:#ffffff;" points="443.811,297.202 443.811,214.798 297.202,214.798 297.202,68.189 214.799,68.189   214.798,214.798 68.189,214.799 68.189,297.202 214.798,297.202 214.798,443.811 297.201,443.811 297.202,297.202 "/>
    `
  }

  private returnMinusSvgHtml (style='fill:#1778f5') {
    return `
    <circle style="${style};" cx="255.996" cy="255.996" r="255.996"/>
    <rect x="81.679" y="214.535" style="fill:#ffffff;" width="348.646" height="82.922"/>
    `
  }
  
  private getContainerSize (): { width: number; height: number; x: number; y: number } {
    // 取得圖表container (div)的尺寸
    try {
      const node = this.el.node()
      return node.getBoundingClientRect()
    } catch (e) {
      throw(e)
    }
  }

  private highlight (d: ForceDirectedChartTypes.ChartNodeRendered): void {
    if (this.noneStopMode === true) {
      return
    }
    // force stop
    this.force.stop()

    let highlightNodes = []
    this.showData.links.forEach(n => {
      if (n._source === d.id || n._target === d.id) {
        if (highlightNodes.includes(n._source) === false) {
          highlightNodes.push(n._source)
        }
        if (highlightNodes.includes(n._target) === false) {
          highlightNodes.push(n._target)
        }
      }
    })
    this.circle.each((c, i, all) => {
      if (highlightNodes.includes(c.id) === true) {
        if (this.highlightLockMode === true) {
          d3.select(all[i]).select('circle').attr('style', c => {
            return `${this.styleConfig._StylesMap.get(c.style.circle)};${this.styleConfig._StylesMap.get('circleLocked')}` // 加上鎖定住的顏色
          })
        } else {
          d3.select(all[i]).select('circle').attr('style', c => this.styleConfig._StylesMap.get(c.style.circle))
        }
        d3.select(all[i]).style('opacity', 1)
      } else {
        d3.select(all[i]).style('opacity', 0.15)
      }
    })
    // this.circle.style('opacity', c => {
    //   if (highlightNodes.includes(c.id) === true) {
    //     return 1
    //   } else {
    //     return 0.15
    //   }
    // })
    this.path.each((c, i, all) => {
      if (c._target === d.id || c._source === d.id) {
        let pathStyle = c.style.path ? this.styleConfig._StylesMap.get(c.style.path)
          : (c.direction === 'top' ? this.styleConfig._StylesMap.get('pathTop') : this.styleConfig._StylesMap.get('pathDown'))
        if (this.highlightLockMode === true) {
          d3.select(all[i]).attr('marker-end', 'url(#arrowLocked)')
          d3.select(all[i]).attr('style', c => {
            return `${pathStyle};${this.styleConfig._StylesMap.get('pathLocked')}` // 加上鎖定住的顏色
          })
        } else {
          d3.select(all[i]).attr('marker-end', `url(#${c.style.arrow || 'arrow'})`)
          d3.select(all[i]).attr('style', c => {
            return pathStyle
          })
        }
        d3.select(all[i]).style('opacity', 1)
      } else {
        d3.select(all[i]).style('opacity', 0.15)
      }
    })
    // this.path.style('opacity', p => {
    //   if (p._target === d.id || p._source === d.id) {
    //     return 1
    //   } else {
    //     return 0.15
    //   }
    // })
    // this.pathText.each((c, i, all) => {
    //   if (c._target === d.id || c._source === d.id) {
    //     d3.select(all[i]).style('opacity', 1)
    //   } else {
    //     d3.select(all[i]).style('opacity', 0.15)
    //   }
    // })
    this.pathText.style('opacity', p => {
      if (p._target === d.id || p._source === d.id) {
        return 1
      } else {
        return 0.15
      }
    })
    // this.circleText.each((c, i, all) => {
    //   if (highlightNodes.includes(c.id) === true) {
    //     d3.select(all[i]).style('opacity', 1)
    //   } else {
    //     d3.select(all[i]).style('opacity', 0.15)
    //   }
    // })
    this.circleText.style('opacity', c => {
      if (highlightNodes.includes(c.id) === true) {
        return 1
      } else {
        return 0.15
      }
    })
    this.circleBtn.style('opacity', c => {
      if (highlightNodes.includes(c.id) === true) {
        return 1
      } else {
        return 0.15
      }
    })
  }

  private removeHighlight (): void {
    // force restart
    this.forceRestart()

    this.circle.style('opacity', 1)
    this.path.style('opacity', 1)
    this.pathText.style('opacity', 1)
    this.circleText.style('opacity', 1)
    this.circleBtn.style('opacity', 1)
  }

  private linkArc (d: ForceDirectedChartTypes.ChartLinkRendered): string {
    // var dx = d.target.x - d.source.x,
    //     dy = d.target.y - d.source.y
    // dr讓方向線變成有弧度的
    //     dr = Math.sqrt(dx * dx + dy * dy);
    // return "M" + d.source.x + "," + d.source.y + "A" + dr + "," + dr + " 0 0,1 " + d.target.x + "," + d.target.y;
    return "M" + d.source.x + "," + d.source.y + " L" + d.target.x + "," + d.target.y;
  }

  private transform (d): string {
    return "translate(" + d.x + "," + d.y + ")";
  }

  private transformCenter (d: ForceDirectedChartTypes.ChartLinkRendered): string {
    const x = d.source.x + ((d.target.x - d.source.x) / 1.8) // 置中的話除2
    const y = d.source.y + ((d.target.y - d.source.y) / 1.8) // 置中的話除2
    return "translate(" + x + "," + y + ")";
  }

  // 設置拖拽及放大縮小
  private setZoom (xCenterOffset = 0, yCenterOffset = 0): void {
    // 滑鼠滾動放大縮小
    this.svg.on('zoom', null)
    this.zoom = d3.zoom().on('zoom', () => {
      // this.svgGroup.attr('transform', d3.event.transform)
      // 偏移的座標由中心點計算
      this.svgGroup.attr('transform', `translate(
        ${d3.event.transform.x + (xCenterOffset * d3.event.transform.k)},
        ${d3.event.transform.y + (yCenterOffset * d3.event.transform.k)}
      ) scale(
        ${d3.event.transform.k}
      )`)
    })
    this.svg.call(this.zoom)

  }

  private drag (): d3.DragBehavior<Element, unknown, unknown> {
    let originHighlightLockMode: boolean // 拖拽前的highlightLockMode

    return d3.drag()
      .on("start", (d: ForceDirectedChartTypes.ChartNodeRendered) => {
        if (!d3.event.active) {
          this.forceRestart()
        }
        d.fx = d.x
        d.fy = d.y

        // 鎖定模式才不會在拖拽過程式觸發到其他事件造成衝突
        originHighlightLockMode = this.highlightLockMode
        this.highlightLockMode = true
        this.noneStopMode = true
        // 動畫會有點卡住所以乾脆拿掉
        if(this.tooltip != null) {
          this.tooltip.remove()
        }
      })
      .on("drag", (d: ForceDirectedChartTypes.ChartNodeRendered) => {
        if (!d3.event.active) {
          this.force.alphaTarget(0)
        }
        d.fx = d3.event.x
        d.fy = d3.event.y
      })
      .on("end", (d: ForceDirectedChartTypes.ChartNodeRendered) => {
        d.fx = null
        d.fy = null

        this.highlightLockMode = originHighlightLockMode // 還原拖拽前的highlightLockMode
        this.noneStopMode = false
      })
  }

  private addPushpin (g: d3.Selection<any, any, any, any>): void {
    if (this.pushpin && this.pushpin.size()) {
      this.pushpin.remove()          
    }
    this.pushpin = g.append('svg')
      .classed('bp__pushpin', true)
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('xmls', 'http://www.w3.org/2000/svg')
      .attr('version', '1.1')
      .attr('width', fnIconSize)
      .attr('height', fnIconSize)
      .attr('viewBox', '0 0 511.993 511.993')
      .attr('x', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
        return returnPushpinLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).x
      })
      .attr('y', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
        return returnPushpinLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y
      })
      .on('click', (d) => {
        d3.event.stopPropagation()

        this.highlightLockMode = !this.highlightLockMode
        if (this.highlightLockMode) {
          this.force.stop()
          this.highlight(d)
        } else {
          this.removeHighlight()
        }
      })
      .on('mouseover', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
        d3.event.stopPropagation()

        if (this.highlightLockMode === true) {
          // this.force.stop() // 不highlight僅停止動作
          return
        }
        d3.select(all[i])
          .transition()
          .duration(50)
          .attr('width', fnIconActiveSize)
          .attr('height', fnIconActiveSize)
          .attr('y', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
            const originY = returnPushpinLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y
            return originY - fnIconActiveOffset
          })
        this.highlight(d)
      })
      .on('mouseout', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
        d3.event.stopPropagation()
        
        if (this.highlightLockMode === true) {
          return
        }
        d3.select(all[i])
          .transition()
          .duration(50)
          .attr('width', fnIconSize)
          .attr('height', fnIconSize)
          .attr('y', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
            return returnPushpinLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y
          })
        this.removeHighlight()
        this.removePushpin()
      })
    d3Utils.svgHtml(this.pushpin, pushpinSvg)
  }

  private removePushpin (): void {
    if (this.pushpin && this.pushpin.size()) {
      this.pushpin.transition().delay(200).remove()
    }
  }

  private render ({ nodes = [], links = []}: {
    nodes: Array<ForceDirectedChartTypes.ChartNode>;
    links: Array<ForceDirectedChartTypes.ChartLink>;
  }): void {
    this.rootID = nodes[0].id
    // 指向線
    const pathUpdate = this.pathG
      .selectAll("path")
      .data(links)
    const pathEnter = pathUpdate
      .enter()
      .append("path")
      .attr("class", "link")
    // console.log('pathEnter')
    // console.log(pathEnter)
    
    this.path = pathUpdate.merge(pathEnter)
      .attr("marker-end", d => `url(#${d.style.arrow || 'arrow'})`)
      .attr("style", (d: ForceDirectedChartTypes.ChartLink) => {
        let pathStyle = d.style.path ? this.styleConfig._StylesMap.get(d.style.path)
          : (d.direction === 'top' ? this.styleConfig._StylesMap.get('pathTop') : this.styleConfig._StylesMap.get('pathDown'))
        return pathStyle
      })

    pathUpdate.exit().remove()

    // 節點
    const circleUpdate = this.circleG
      .selectAll(".nodeG")
      .data(nodes)
    const circleEnter = circleUpdate
      .enter()
      .append("g")
      .attr("class", "nodeG")
    circleEnter
      .append("circle")
      .attr("class", "node")

    this.circle = circleUpdate.merge(circleEnter)
    this.circle
      .select("circle")
      .attr("r", (d: ForceDirectedChartTypes.ChartNode) => {
        return this.styleConfig._CirclesRMap.get(d.style.circle)
      })
      .attr("style", d => {
        return this.styleConfig._StylesMap.get(d.style.circle)
      })
      .on('mouseover', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
        // -- tooltip --
        const content = d.tooltip
        if (content) {
          const x = d3.event.clientX + 20
          const y = d3.event.clientY + 20
          this.tooltip = new Tooltip(this.el, content, x, y, true)
        }
      })
      .on('mousemove', () => {
        // -- tooltip --
        if(this.tooltip != null) {
          const x = d3.event.clientX + 20
          const y = d3.event.clientY + 20
          this.tooltip.move(x, y)
        }
      })
      .on('mouseout', () => {
        // -- tooltip --
        if(this.tooltip != null) {
          this.tooltip.remove()
        }
      })

    this.circle
      .on('mouseover', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
        // -- highlight --
        if (this.highlightLockMode === true) {
          // force stop
          if (this.noneStopMode === false) {
            this.force.stop() // 不highlight僅停止動作
          }
          return
        }
        this.highlight(d)
        
        // -- 增加圖釘icon --
        const g = d3.select(all[i])
        this.addPushpin(g)
      })
      .on('mouseout', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
        // -- 取消highlight --
        if (this.highlightLockMode === true) {
          return
        }
        this.removeHighlight()

        // -- 展開icon動畫 --
        const g = d3.select(all[i])

        // -- 移除圖釘icon --
        this.removePushpin()
      })
      .call(this.drag())

    // 泡泡事件
    this.circle.select('.node')
      .on('click', (item: ForceDirectedChartTypes.ChartNodeRendered) => {
        this.nodeClickCallback(item)
      })    

    // 增加展開icon
    // this.circle.each((d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
    //   const g = d3.select(all[i])
    //   const node = this.showData._NodesMap.get(d.id)
    //   if (node && node.hasTop) {
    //     // let iconX = 100
    //     // try {
    //     //   iconX = getExpendIconX(g.select("circle").attr('width'))
    //     // } catch (e) { console.error(e) }
    //     let gSvgClassName = ''
    //     let svgContent = ''
    //     if (node.isTopExpanded === true) {
    //       gSvgClassName = 'bp__minus-btn'
    //       svgContent = this.minusSvgHtml
    //     } else {
    //       gSvgClassName = 'bp__plus-btn'
    //       svgContent = this.plusSvgHtml
    //     }
    //     g.selectAll('.bp__plus-btn').remove()
    //     g.selectAll('.bp__minus-btn').remove()
    //     const circleR = this.styleConfig._CirclesRMap.get(d.style.circle)
    //     const gSvg = g.append('svg')
    //       .classed(gSvgClassName, true)
    //       .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
    //       .attr('xmls', 'http://www.w3.org/2000/svg')
    //       .attr('version', '1.1')
    //       .attr('width', fnIconSize)
    //       .attr('height', fnIconSize)
    //       .attr('viewBox', '0 0 511.993 511.993')
    //       .attr('x', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
    //         return circleR
    //       })
    //       .attr('y', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
    //         return - circleR + 20
    //       })
    //     gSvg
    //       .on('click', (item: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
    //         if (this.highlightLockMode === true) {
    //           return
    //         }
    //         // 避免滑鼠再次mouseover畫面又停住
    //         this.noneStopMode = true
    //         setTimeout(() => {
    //           this.noneStopMode = false
    //         }, 1000)

    //         this.removeHighlight()
    //         this.forceRestart() // 因靜止動作會讓點擊後要做的動畫不動，所以要先restart
    //         this.extandBtnClickCallback(item)
    //       })
    //       .on('mouseover', (d, i, all) => {
    //         d3.select(all[i])
    //           .transition()
    //           .duration(50)
    //           .attr('width', fnIconActiveSize)
    //           .attr('height', fnIconActiveSize)
    //           .attr('y', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
    //             return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y - fnIconActiveOffset
    //           })
    //       })
    //       .on('mouseout', (d, i, all) => {
    //         d3.select(all[i])
    //           .transition()
    //           .delay(100)
    //           .duration(100)
    //           // .attr('x', iconX)
    //           .attr('width', fnIconSize)
    //           .attr('height', fnIconSize)
    //           .attr('x', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
    //             return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).x
    //           })
    //           .attr('y', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
    //             return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y
    //           })
    //       })
        
    //     d3Utils.svgHtml(gSvg, svgContent)
    //   }
    // })

    circleUpdate.exit().remove()

    // 指向線文字
    const pathTextUpdate = this.pathTextG
      .selectAll("text")      
      .data(links)
    const pathTextEnter = pathTextUpdate
      .enter()
      .append("text")
      .attr("class", "link-text")
      .attr("x", 8)
      .attr("y", ".31em")
      .attr("fill", "#888")
      .attr('id', (d: ForceDirectedChartTypes.ChartLink) => d.index)
      .attr("style", this.styleConfig._StylesMap.get('pathText'))
      .text((d: ForceDirectedChartTypes.ChartLink) => d.label)
    
    this.pathText = pathTextUpdate.merge(pathTextEnter)

    pathTextUpdate.exit().remove()
    

    // 節點文字
    // const circleTextUpdate = this.circleTextG
    //   .selectAll("text")
    //   .data(nodes)
    // const circleTextEnter = circleTextUpdate
    //   .enter()
    //   .append("text")
    //   .attr("class", "node-text")
    //   .attr('id', (d: ForceDirectedChartTypes.ChartNode) => d.id)
    //   .attr("x", -8)
    //   .attr("y", ".31em")
    //   .attr("fill", "#333")
    //   .attr("style", styleDict.circleText)
    //   .text((d: ForceDirectedChartTypes.ChartNode) => d.label)
    // circleTextUpdate.exit().remove()
    // this.circleText = circleTextUpdate.merge(circleTextEnter)
    const circleTextUpdate = this.circleTextG
      .selectAll(".textG")
      .data(nodes)
    const circleTextEnter = circleTextUpdate
      .enter()
      .append("g")
      .attr("class", "textG")
      .attr("text-anchor", "middle")
      .style("font-size", "10px")
      // .attr("width", d => {
      //   return circleRDict[d.style.circle] * 2
      // })
      // .attr("height", d => {
      //   return circleRDict[d.style.circle] * 2
      // })
    circleTextEnter.append('text')
      .attr('pointer-events', 'none')
    this.circleText = circleTextUpdate.merge(circleTextEnter)
    this.circleText
      .select('text')
      .attr('style', s => this.styleConfig._StylesMap.get(s.style.circleText))
    this.circleText
      .each((d: ForceDirectedChartTypes.ChartNode, i, all) => {
        let g = d3.select(all[i])
        //   .style("font-size", "10px")
        // g.append('text')
        //   .attr('pointer-events', 'none')
        //   .attr('style', s => this.styleConfig._StylesMap.get(s.style.circleText))
          
        let r = this.styleConfig._CirclesRMap.get(d.style.circle) || 0
        let isBreakAll = false
        if (d.label.length > 4) {
          isBreakAll = true
        }
        d3Utils.fitTextToCircle(g, d.label, r, 12, isBreakAll)
      })

    

    circleTextUpdate.exit().remove()

    const circleBtnUpdate = this.circleBtnG
      .selectAll(".btnG")
      .data(nodes)
    const circleBtnEnter = circleBtnUpdate
      .enter()
      .append("g")
      .classed("btnG", true)
    circleBtnEnter
      .append("svg")
      .classed("btnSVG", true)
      .attr('id', d => `circleBtnSvg__${d.id}`)
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('xmls', 'http://www.w3.org/2000/svg')
      .attr('version', '1.1')
      .attr('viewBox', '0 0 511.993 511.993')
    // circleBtnSvgEnter.append('circle')
    //   .attr('cx', '255.996')
    //   .attr('cy', '255.996')
    //   .attr('r', '255.996')
    // circleBtnSvgEnter.append('rect')
    //   .attr('')
    this.circleBtn = circleBtnUpdate.merge(circleBtnEnter)
    const circleBtnSvg = this.circleBtn.select('.btnSVG')
      .classed('bp__minus-btn', d => {
        if (d.isTopExpanded === true) {
          return true
        } else {
          return false
        }
      })
      .classed('bp__plus-btn', d => {
        if (d.isTopExpanded === false) {
          return true
        } else {
          return false
        }
      })
      .attr('width', fnIconSize)
      .attr('height', fnIconSize)
      .attr('x', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
        return this.styleConfig._CirclesRMap.get(d.style.circle)
      })
      .attr('y', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
        const circleR = this.styleConfig._CirclesRMap.get(d.style.circle)
        return - circleR + 20
      })
      .on('click', (item: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
        if (this.highlightLockMode === true) {
          return
        }
        // 避免滑鼠再次mouseover畫面又停住
        this.noneStopMode = true
        setTimeout(() => {
          this.noneStopMode = false
        }, 1000)

        this.removeHighlight()
        this.forceRestart() // 因靜止動作會讓點擊後要做的動畫不動，所以要先restart
        this.extandBtnClickCallback(item)
      })
      .on('mouseover', (d, i, all) => {
        console.log(d)
        this.highlight(d)
        d3.select(all[i])
          .transition()
          .duration(50)
          .attr('width', fnIconActiveSize)
          .attr('height', fnIconActiveSize)
          .attr('y', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
            return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y - fnIconActiveOffset
          })
      })
      .on('mouseout', (d, i, all) => {
        this.removeHighlight()
        d3.select(all[i])
          .transition()
          .delay(100)
          .duration(100)
          // .attr('x', iconX)
          .attr('width', fnIconSize)
          .attr('height', fnIconSize)
          .attr('x', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
            return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).x
          })
          .attr('y', (d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
            return returnExpandBtnLocation(this.styleConfig._CirclesRMap.get(d.style.circle)).y
          })
      })
    
    this.circleBtnG.selectAll('circle').remove()
    this.circleBtnG.selectAll('polygon').remove()
    circleBtnSvg.each((d: ForceDirectedChartTypes.ChartNodeRendered, i, all) => {
      if (d.hasTop === true) {
        const gSvg = d3.select(all[i])
        if (d.isTopExpanded === true) {
          d3Utils.svgHtml(gSvg, this.minusSvgHtml)
        } else {
          d3Utils.svgHtml(gSvg, this.plusSvgHtml)
        }
      }
    })
    
    

    // console.log(JSON.parse(JSON.stringify(links)))

    // console.log(links)
    this.force.nodes(nodes)
      .on('tick', () => {
        this.path.attr("d", this.linkArc)
        this.circle.attr("transform", this.transform)
        this.pathText.attr("transform", this.transformCenter)
        this.circleText.attr("transform", this.transform)
        this.circleBtn.attr("transform", this.transform)
      })
    this.force.force("link").links(links)
    // force.force(
    //   "link",
    //   d3.forceLink(links)
    //     .strength(0.5)
    //     .distance(80)
    //     .id(d => {console.log(d); return d.id})
    // )
    // force restart
    this.forceRestart()

    // console.log({nodes, links})
  }

  // public getNodeClass (): ForceDirectedChartTypes.ChartNodeClass {
  //   return nodeClass
  // }
  public setStyle (returnStyle): void {
    const newStyleConfig = returnStyle(this.styleConfig)
    // 建立_StylesMap
    let NewStylesMap = new Map()
    Object.keys(newStyleConfig.styles).forEach(key => {
      const value = newStyleConfig.styles[key]
      NewStylesMap.set(key, value)
    })
    newStyleConfig._StylesMap = NewStylesMap
    // 建立_CirclesRMap
    let NewCirclesRMap = new Map()
    Object.keys(newStyleConfig.circlesR).forEach(key => {
      const value = newStyleConfig.circlesR[key]
      NewCirclesRMap.set(key, value)
    })
    newStyleConfig._CirclesRMap = NewCirclesRMap
    this.styleConfig = newStyleConfig
    // 設定展開按鈕顏色
    this.plusSvgHtml = this.returnPlusSvgHtml(this.styleConfig._StylesMap.get('expandBtn'))
    this.minusSvgHtml = this.returnMinusSvgHtml(this.styleConfig._StylesMap.get('expandBtn'))
    // 建立箭頭
    this.setArrowMarker()
  }

  public onNodeClick (callback): void {
    this.nodeClickCallback = callback
  }

  public onExtandBtnClick (callback): void {
    this.extandBtnClickCallback = callback
  }

  // 初始化
  public init ({ nodes = [], links = [], expandAll = false }: {
    nodes: Array<ForceDirectedChartTypes.ChartNode>;
    links: Array<ForceDirectedChartTypes.ChartLink>;
    expandAll: boolean;
  }): void {
    // 記住資料
    // this.allData.nodes = nodes
    // this.allData.links = links
    // this.allData._NodesMap = {}
    // nodes.forEach((d: ForceDirectedChartTypes.ChartNode) => {
    //   this.allData._NodesMap[d.id] = d
    // })
    this.resetNodes({ nodes, links })

    // 實際呈現的資料
    this.showData = JSON.parse(JSON.stringify(this.allData))
    if (expandAll === false) {
      // 預設第一筆為根節點
      const rootNode = this.showData.nodes[0] ? this.showData.nodes[0].id : ''
      // 全部顯示的節點
      let allShowNodes = [rootNode]
      for (const link of this.showData.links) {
        if (link._source === rootNode) {
          allShowNodes.push(link._target)
        }
        if (link._target === rootNode) {
          allShowNodes.push(link._source)
        }
      }
      // root上下游一層的連結
      const allShowLinks = this.showData.links.filter(d => {
        if (d._source === rootNode || d._target === rootNode) {
          return true
        }
        return false
      })
      // // root上下游一層全部的連結
      // const allShowLinks = this.showData.links.filter(d => {
      //   if (allShowNodes.includes(d._source) && allShowNodes.includes(d._target)) {
      //     return true
      //   }
      //   return false
      // })
      this.showData.links = allShowLinks

      this.showData.nodes = this.showData.nodes.filter(d => {
        if (allShowNodes.includes(d.id)) {
          return true
        }
        return false
      })
    }
    // _NodesMap
    this.showData._NodesMap = new Map()
    this.showData.nodes.forEach((d: ForceDirectedChartTypes.ChartNode) => {
      this.showData._NodesMap.set(d.id, d)
    })
    // console.log(this.allData)
    // console.log(this.showData)
    // return
    this.render({
      nodes: this.showData.nodes,
      links: this.showData.links,
    })
    this.setZoom(0, 0)
  }

  // 重設nodes和links（不展開）
  public resetNodes ({ nodes = [], links = [] }: {
    nodes: Array<ForceDirectedChartTypes.ChartNode>;
    links: Array<ForceDirectedChartTypes.ChartLink>;
    // center?: boolean // 置中
  }): void {
    // 記住資料
    this.allData.nodes = nodes
    this.allData.links = links
    this.allData._NodesMap = new Map()
    nodes.forEach((d: ForceDirectedChartTypes.ChartNode) => {
      this.allData._NodesMap.set(d.id, d)
    })
  }

  public toggleTopNodes (id: string): void {
    // 找到目標節點，並將該節點展開狀態反過來（開或關）
    let findAllNodeID = null
    let findShowNodeID = null
    let currentIsTopExpanded = false
    for (let i in this.allData.nodes) {
      if (this.allData.nodes[i].id === id) {
        // this.allData.nodes[i].isTopExpanded = !this.allData.nodes[i].isTopExpanded
        // findAllNode = this.allData.nodes[i]
        findAllNodeID = i
        break
      }
    }
    for (let i in this.showData.nodes) {
      if (this.showData.nodes[i].id === id) {
        // this.showData.nodes[i].isTopExpanded = !this.showData.nodes[i].isTopExpanded
        // findShowNode = this.allData.nodes[i]
        findShowNodeID = i
        break
      }
    }
    if (findAllNodeID == null || findShowNodeID == null) {
      return
    }
    currentIsTopExpanded = this.showData.nodes[findShowNodeID].isTopExpanded
    this.allData.nodes[findAllNodeID].isTopExpanded = !currentIsTopExpanded
    this.showData.nodes[findShowNodeID].isTopExpanded = !currentIsTopExpanded

    // -- 重新計算要呈現的節點data --
    if (this.showData.nodes[findShowNodeID].isTopExpanded === true) {
      const { nodes, links } = addLoop(this.showData, this.allData, id)
      this.showData.nodes = nodes
      this.showData.links = links
    } else {
      // 關閉node將該node指向的node移除掉
      // this.showData = JSON.parse(JSON.stringify(this.showData))
      const { nodes, links } = deleteLoop(this.showData, this.allData, id)
      this.showData.nodes = nodes
      this.showData.links = links
    }
    this.showData._NodesMap = new Map()
    this.showData.nodes.forEach((d: ForceDirectedChartTypes.ChartNode) => {
      this.showData._NodesMap.set(d.id, d)
    })
    // console.log(this.showData)
    // console.log(JSON.parse(JSON.stringify(this.showData)))
    this.render({
      nodes: this.showData.nodes,
      links: this.showData.links,
    })
  }

  public getChartSize (): { width: number; height: number } {
    let width = 0
    let height = 0
    // let x = 0
    // let y = 0
    try {
      const getBBox = this.svgGroup.node().getBBox()
      width = getBBox.width
      height = getBBox.height
      // const transform = this.svgGroup.attr('transform')
      // x = Number(transform.slice(transform.indexOf('(') + 1, transform.indexOf(',')))
      // y = Number(transform.slice(transform.indexOf(',') + 1, transform.indexOf(')')))
    } catch (e) { console.error(e) }

    return { width, height }
  }

  // 置中
  public resize (): void {
    // reset zoom scale
    if (this.zoom && this.zoom.transform) {
      this.svg.call(this.zoom.transform, d3.zoomIdentity.scale(1));
    }
    // 取得圖表container (div)的尺寸
    let size = { width: 0, height: 0 }
    try {
      size = this.getContainerSize()
      if (!size || !size.width || !size.height) {
        return
      }
      // console.log('getContainerSize')
      // console.log(this.el)
      // console.log({width, height})
      // 重設svg尺寸
      this.svg.attr('width', `${size.width}px`)
      this.svg.attr('height', `${size.height}px`)
      // 置中偏移
      // const chartSize = this.getChartSize()
      // const xCenterOffset = (Number(width) - chartSize.width) / 2
      // const yCenterOffset = (Number(height) - chartSize.height) / 2
      const xCenterOffset = 0 // 因為繪圖時就已置中所以偏移設0即可
      const yCenterOffset = 0 // 因為繪圖時就已置中所以偏移設0即可
      // 置中
      this.svgGroup.attr("transform", `translate(${xCenterOffset}, ${yCenterOffset}) scale(1)`)
      // force置中
      this.force.force("center", d3.forceCenter(size.width / 2, size.height / 2))
      // 滑鼠滾動放大縮小
      this.setZoom(xCenterOffset, yCenterOffset)
      // force restart
      this.forceRestart()
    } catch (e) {
      console.error(e)
      return
    }
  }

  // force restart
  public forceRestart (): void {
    
    this.force.alphaTarget(0.1).restart()

    // 10秒後自動停止
    this.countdown = 10
    if (this.isCountingdown === true) {
      return // 如果倒數正在進行中則不啟動另一次倒數
    }
    this.isCountingdown = true
    let countdown = () => {
      if (this.countdown > 0) {
        this.countdown -= 1
        setTimeout(() => countdown(), 1000)
      } else {
        this.force.stop()
        this.isCountingdown = false
      }
    }
    countdown()
  }

  public forceStop (): void {
    // this.highlightLockMode = true
    this.force.stop()
  }
}
