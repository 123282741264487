export default {  
  ajax (option) {
    if (!option.url) {
      return;
    }
    var xhr = window.XMLHttpRequest &&
      (window.location.protocol !== 'file:' ||
        !window.ActiveXObject) ?
      function () {
        return new XMLHttpRequest();
      } :
      function () {
        try {
          return new ActiveXObject('Microsoft.XMLHTTP');
        } catch (e) {
          throw new Error('XMLHttpRequest not supported');
        }
      };

    option.type = option.type || 'GET';
    option.header = option.header || {
      // 'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type': 'application/json;charset=UTF-8'
    };
    option.success = option.success || function () {};
    option.error = option.error || function () {};

    var request = xhr();
    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        if (request.status === 200) {
          let response = null
          try {
            response = JSON.parse(request.response || request.responseText) // @Q@ 之前寫request.response，可是用mock.js發現要用request.responseText，待接真實api後確認
          } catch (e) {}
          option.success.call(response, response);
        } else {
          option.error.call(request, request);
        }
      }
    };

    var body = null;
    var url = option.url;
    if (option.data) {
      if (option.type === 'POST') {
        // body = param(option.data);
        body = JSON.stringify(option.data)
      } else {
        url = option.url + '?' + param(option.data) +
          '&time=' + new Date().getTime();
      }
    }

    request.open(option.type, url);
    for (var name in option.header) {
      request.setRequestHeader(
        name, option.header[name]);
    }
    request.send(body);
  },
  goToChoose () {
    if(!window){
      return false
    }
    // window.location.href='https://choose.blueplanet.com.tw'
    window.open('https://choose.blueplanet.com.tw')
  },
  isIEBrowser () {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串 
    var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器 
    // var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器 
    var isIE = window.ActiveXObject || "ActiveXObject" in window
    // var isEdge = userAgent.indexOf("Windows NT 6.1; Trident/7.0;") > -1 && !isIE; //判断是否IE的Edge浏览器 
    var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
    var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器 
    var isSafari = userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器 
    var isChrome = userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1 && !isEdge; //判断Chrome浏览器 

    if (isIE) {
      return true
    } else {
      return false
    }
  },
  getPageHref () {
    // 使用iframe，取得外層網頁網址
    if (window.document.referrer) return window.document.referrer
    // 本身網頁網址
    else return window.location.href
  },
  // deep clone
  clone: function (obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  // 捲動畫面觸發事件
  addScrollTrigger (dom, callback, screenPercent = 0.5) { // 預設觸發點為螢幕高度*0.5
    let active = true
    const triggerEvent = () => {
      const screenHeight = window.innerHeight || 0
      const triggerHeight = screenHeight * screenPercent
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const domTop = dom.offsetTop || 0
      // 觸發
      if (domTop - scrollTop <= triggerHeight) {
        active = false
        window.document.removeEventListener('scroll', triggerEvent)
        callback()
      }
    }
    window.document.addEventListener('scroll', triggerEvent)
    setTimeout(() => {
      if (active) {
        triggerEvent()
      }
    })
  },
  // 加上千分位 ,
  toCurrency (num = 0) {
    var parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  },
  // 百分比數字
  toPercentString (num, decimal = 0, unit = '%') {
    if (isNaN(num)) {
      num = 0
    }
    num = Number(num).toFixed(decimal)
    return num.toString() + unit
  },
  dateTimeToString (date) {
    return date.getFullYear() + '-' + ((date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + " " + date.getHours() + ":30";
  },
  get24HourDateTimeRange () {
    let Today = new Date()
    let yesterday = new Date().setDate(Today.getDate() - 1)
    let Yesterday = new Date(yesterday)
    return {
      startDate: this.dateTimeToString(Yesterday),
      endDate: this.dateTimeToString(Today)
    }
  }
}
