require('./emotion_percentage_bar_list.scss')

import * as d3 from 'd3'
import NumberTooltip from '../../components/NumberTooltip/NumberTooltip.js'
import Tooltip from '../../components/Tooltip/Tooltip.ts'
import * as codes from '../../utils/codes.js'
import utils from '../../utils/utils.js'

export default class EmotionPercentageBarList {
  constructor (el, params) {
    this.width = params.options.width // svg寬度
    this.height = params.options.height // svg寬度
    this.maxBarWidth = this.width - 50
    this.componentEl = el
      .append('div')
      .classed('choose__emotion-percentage-bar-list', true)
      .attr('width', this.width + 'px')
      .attr('height', this.height + 'px')
    
  }

  _returnItemFullData (item, maxValue) {
    let itemWidth = (item.total / maxValue) * this.maxBarWidth
    // const defaultWidth = this.width / 3
    let  negativeRate = item.negative / item.total
    let  negativeWidth = negativeRate * itemWidth
    let  neutralRate = item.neutral / item.total
    let  neutralWidth = neutralRate * itemWidth
    let  positiveRate = item.positive / item.total
    let  positiveWidth = positiveRate * itemWidth
    
    // -- 新增資料到原來的data --
    item.width = itemWidth
    item.block = [
      {
        name: 'negative',
        count: item.negative,
        percent: negativeRate * 100,
        width: negativeWidth,
        left: 0
      },
      {
        name: 'neutral',
        count: item.neutral,
        percent: neutralRate * 100,
        width: neutralWidth,
        left: negativeWidth
      },
      {
        name: 'positive',
        count: item.positive,
        percent: positiveRate * 100,
        width: positiveWidth,
        left: negativeWidth + neutralWidth
      }
    ]
    return item
  }

  _setSelection (el) {
    el.each((itemData, itemIndex, nodes) => {
      const itemEl = d3.select(nodes[itemIndex])
      const blockDataArr = itemData.block 

      // 標題
      let tooltip
      const titleEl = itemEl.select('.choose__emotion-percentage-bar-list__title')
        .attr('href', itemData.url)
        .text(itemData.name)
        .on('mouseover', () => {
          let x = d3.event.clientX + 20
          let y = d3.event.clientY
          tooltip = new Tooltip(this.componentEl, `前往 ${itemData.name} 動態時報`, x, y)
        })
        .on('mousemove', d => {
          if(tooltip != null) {
            let x = d3.event.clientX + 20
            let y = d3.event.clientY
            tooltip.move(x, y)
          }
        })
        .on('mouseout', d => {
          if(tooltip != null) {
            tooltip.remove()
          }
        })
      // bar及數字
      const contentEl = itemEl.select('.choose__emotion-percentage-bar-list__content')
      // bar
      const barEl = contentEl.select('.choose__emotion-percentage-bar-list__bar')
        .style('width', `${itemData.width}px`)
      // 數字
      const numberEl = contentEl.select('.choose__emotion-percentage-bar-list__number')
        .text(utils.toCurrency(itemData.total))

      // -- 情緒區塊 --
      for (let index in blockDataArr) {
        const blockData = blockDataArr[index]
        const blockEl = barEl.select('.choose--' + blockData.name)
        let numberTooltip
        blockEl.on('mouseover', d => {
          let x = d3.event.clientX + 20
          let y = d3.event.clientY
          let content = {
            title: codes.EMOTION_NAME[blockData.name],
            count: blockData.count,
            percent: blockData.percent,
            unit: '則'
          }
          numberTooltip = new NumberTooltip(this.componentEl, content, x, y)
        })
        blockEl.on('mousemove', d => {
          let x = d3.event.clientX + 20
          let y = d3.event.clientY
          numberTooltip.move(x, y)
        })
        blockEl.on('mouseout', d => {
          if(numberTooltip != null) {
            numberTooltip.remove()
          }
        })
      }
    })
    
  }

  render (data) {
    if (data.length == 0) {
      return
    }
    // 增加總合到各別data上
    data = data.map(d => {
      d.total = d.positive + d.neutral + d.negative
      return d
    })
    // 排序
    data = data.sort((a, b) => b.total - a.total)
    // 增加資料到data上
    data = data.map((d, i) => this._returnItemFullData(d, data[0].total))
    
    let update = this.componentEl
      .selectAll('div.choose__emotion-percentage-bar-list__item')
      .data(data)
    let enter = update
      .enter()
      .append('div')
      .classed('choose__emotion-percentage-bar-list__item', true)
    let exit = update.exit()
    let merge = enter
      .merge(update)

    // -- 各別bar加入元素 --
    enter.each((itemData, itemIndex, all) => {
      const blockData = itemData.block
      // 標題
      const titleEl = d3.select(all[itemIndex])
        .append('div')
        .append('a')
        .classed('choose__emotion-percentage-bar-list__title', true)
      // bar及數字
      const contentEl = d3.select(all[itemIndex])
        .append('div')
        .classed('choose__emotion-percentage-bar-list__content', true)
      // bar
      const barEl = contentEl
        .append('div')
        .classed('choose__emotion-percentage-bar-list__bar', true)
      // 數字
      const numberEl = contentEl
        .append('div')
        .classed('choose__emotion-percentage-bar-list__number', true)

      // -- 情緒區塊 --
      for (let index in blockData) {
        const itemData = blockData[index]
        barEl.append('div')
          .classed('choose__emotion-percentage-bar-list__bar-block', true)
          .classed('choose--' + itemData.name, true)
          .style('width', `${itemData.width}px`)
          .style('left', `${itemData.left}px`)
      }
    })
    
    this._setSelection(merge)

  }
}
