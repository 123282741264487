import d3 from 'd3'

export default {
  // svg加入html @Q@ 因ie上svg不支援innerHTML，所以用這個function取代
  svgHtml (selection, htmlString) {
    const parseSVG = s => {
      var div= document.createElementNS('http://www.w3.org/1999/xhtml', 'div');
      div.innerHTML= '<svg xmlns="http://www.w3.org/2000/svg">'+ s +'</svg>';
      var frag= document.createDocumentFragment();
      while (div.firstChild.firstChild)
          frag.appendChild(div.firstChild.firstChild);
      return frag;
    }
    // 刪除現有子節點
    const node = selection.node()
    while(node.hasChildNodes())
    {
      node.removeChild(node.firstChild);
    }
    // 加入dom
    selection.node().appendChild(parseSVG(htmlString))
  },
  fitTextToCircle (svg, text, radius, lineHeight, isBreakAll = false) {
    if (svg == null || text == null) {
      console.error("svg or text is not defined")
      return
    }
    if (radius == null) {
      const getBox = svg.node().getBBox()
      radius = getBox.width / 2
    }
  
    function getWords (text) {
      let words
      if (isBreakAll) {
        words = text.split('')
      } else {
        words = text.split(/\s+/g) // To hyphenate: /\s+|(?<=-)/
      }
      if (!words[words.length - 1]) words.pop()
      if (!words[0]) words.shift()
      return words
    }
  
    function measureWidth (text) {
      const context = document.createElement("canvas").getContext("2d")
      // return text => context.measureText(text).width
      return context.measureText(text).width
    }
  
    function getTargetWidth (text) {
      const m = measureWidth(text.trim())
      const result = Math.sqrt(m * lineHeight)
      return result
      // return(
        // Math.sqrt(measureWidth(text.trim()) * lineHeight)
      // )
    }
  
    function getLines (words, targetWidth) {
      let line
      let lineWidth0 = Infinity
      const lines = []
      let space = " "
      if (isBreakAll) {
        space = ""
      }
      for (let i = 0, n = words.length; i < n; ++i) {
        let lineText1 = (line ? line.text + space : "") + words[i]
        let lineWidth1 = measureWidth(lineText1)
        if ((lineWidth0 + lineWidth1) / 2 < targetWidth) {
          line.width = lineWidth0 = lineWidth1
          line.text = lineText1
        } else {
          lineWidth0 = measureWidth(words[i])
          line = {width: lineWidth0, text: words[i]}
          lines.push(line)
        }
      }
      return lines;
    }
  
    function getTextRadius (lines) {
      let radius = 0
      for (let i = 0, n = lines.length; i < n; ++i) {
        const dy = (Math.abs(i - n / 2 + 0.5) + 0.5) * lineHeight
        const dx = lines[i].width / 2
        radius = Math.max(radius, Math.sqrt(dx ** 2 + dy ** 2))
      }
      return radius
    }
  
    function draw (svg, text) {
      const words = getWords(text)
      const targetWidth = getTargetWidth(text)
      const lines = getLines(words, targetWidth)
      const textRadius = getTextRadius(lines)
  
      let t = svg.select("text")
      if (!t.size()) {
        t = svg.append("text")
      }
      t.attr("transform", `translate(${0},${0}) scale(${radius / textRadius})`)
      const tspan = t.selectAll("tspan")
        .data(lines)
      tspan.enter()
        .append("tspan")
        .attr("x", 0)
        .merge(tspan)
        .attr("y", (d, i) => (i - lines.length / 2 + 0.8) * lineHeight)
        .text(d => d.text)
      tspan.exit().remove()
  
      return svg.node()
    }
  
    draw(svg, text)
  }
}