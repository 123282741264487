// @ts-nocheck
require('./donut_chart.scss')

import ReactionItemList from '../ReactionItemList/ReactionItemList'
import ReactionIcon from '../ReactionIcon/ReactionIcon.js'
import * as DonutChartTypes from '../../types/components/donutChart'
// import { select, selectAll, update, remove, merge, data, attr, classed, style, html } from 'd3-selection'
// import { sum } from 'd3-array'
// import { transition, duration, ease, tween } from 'd3-transition'
// import { easeElastic } from 'd3-ease'
// import { scaleOrdinal } from 'd3-scale'
// import { arc, pie } from 'd3-shape'
// const d3 = {
//   select,
//   selectAll,
//   sum,
//   transition,
//   easeElastic,
//   scaleOrdinal,
//   arc,
//   pie
// }
import * as d3 from 'd3'
import utils from '../../utils/utils.js'
// declare function render (data: Array<DonutChartTypes.DonutChartRenderData>): void

export default class DonutChart implements DonutChartTypes.IDonutChart {
  private colorScale: d3.ScaleOrdinal<string, any>
  private width: number
  private height: number
  private markX: number
  private markY: number
  private translateX: number
  private translateY: number
  private dataSet: Array<DonutChartTypes.DonutChartDataSet> = [] // 整理後的資料
  private maxData: any = {} // 最大值資料
  private sumValue: number = 0
  private chartG: d3.Selection<d3.BaseType, any, any, any>
  private centralInfoG: d3.Selection<d3.BaseType, any, any, any>
  private centralFontSize: number = 24
  private centralIconDiameter: number = 60
  private arc: d3.Arc<any, d3.DefaultArcObject>
  private arcMouseover: d3.Arc<any, d3.DefaultArcObject>
  private centralIcon: any // 中間icon @Q@ 晚點補型別
  private buttomList: ReactionItemList // @Q@ 晚點補型別

  constructor (el: d3.Selection<d3.BaseType, any, any, any>, props: DonutChartTypes.DonutChartProps) {
    // this.colorSet = config.colorSet
    this.colorScale = props.colorScale
    this.width = props.width
    this.height = props.height - 60
    this.markX = this.width / 2
    this.markY = this.height / 2
    this.translateX = this.width / 2 // 元素起始座標為置中
    this.translateY = this.height / 2
    // this.dataSet = [] // 整理後的資料
    // this.maxData = {} // 最大值資料
    // this.sumValue = 0

    const svg = el.append('svg')
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('xmls', 'http://www.w3.org/2000/svg')
      .attr('version', '1.1')
      .attr('width',this.width)
      .attr('height',this.height)
    // 甜甜圈圖svg
    this.chartG = svg.append('g')
    // 中間資訊svg（圖層較高）
    this.centralInfoG = svg.append('g')

    const outerRadius = this.height / 2.2
    const innerRadius = this.height / 3.2
    const outerMouseoverRadius = this.height / 2.1
    // this.centralFontSize = 24
    // this.centralIconDiameter = 60
    // 依據size改變中間資訊大小
    if (outerRadius >= 120) {
      this.centralFontSize = 24
      this.centralIconDiameter = 60
    } else {
      this.centralFontSize = 20
      this.centralIconDiameter = 50
    }


    // 弧產生器
    this.arc = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
    // 滑鼠移過的弧產生器
    this.arcMouseover = d3.arc()
      .innerRadius(innerRadius)
      .outerRadius(outerMouseoverRadius)

    // // 中間icon
    // this.centralIcon

    // 底部選單
    let listEl = el.append('div')
      .classed('choose__reaction-item-list-box', true)
    this.buttomList = new ReactionItemList(listEl)
  }
  
  // 設定中間資訊"次數"的字型大小
  // _setCentralFontSize (selection, number) {
  //   if (number >= 1000000) {
  //     selection.style('font-size', '24px')
  //   } else {
  //     selection.style('font-size', '24px')
  //   }
  // }

  // 初始化中間資訊
  private _initCentralInfo (selection, data) {
    // 第一名文字
    const numberEl = selection.append('text')
      .classed('choose__donut-chart__center-number', true)
      .style('font-size', this.centralFontSize)
      .attr('x', this.markX)
      .attr('y', this.markY)
      .attr('transform', `translate(0, ${this.centralFontSize + 12})`)
      .attr('text-anchor', 'middle')
      .text(data.countText)
    // this._setCentralFontSize(numberEl, data.value)
    selection.append('text')
      .classed('choose__donut-chart__center-percent', true)
      .attr('x', this.markX)
      .attr('y', this.markY)
      .attr('transform', `translate(0, ${this.centralFontSize + 34})`)
      .attr('text-anchor', 'middle')
      .text(data.percentText)
    const iconRadius = this.centralIconDiameter / 2
    this.centralIcon = new ReactionIcon(selection, data.name, {
        x: this.markX - iconRadius,
        y: this.markY - iconRadius - 20,
        width: this.centralIconDiameter,
        height: this.centralIconDiameter
      })
  }

  // 更換中間資訊
  private _changeCentralInfo (selection, data) {
    // 中間表情符號
    this.centralIcon.change(data.name)
    // d3.select('.choose__donut-chart__center-title')
    //   .text(maxName)
    const numberEl = selection.select('.choose__donut-chart__center-number')
      .text(data.countText)
    // this._setCentralFontSize(numberEl, data.value)
    selection.select('.choose__donut-chart__center-percent')
      .text(data.percentText)
  }

  // 區塊放大動畫
  private _setHighlight (circleEl, highlightEl) {
    let highlightData = highlightEl.data()[0]

    circleEl.attr('d', (d) => {
      return this.arc(d)
    })
    
    highlightEl.select('path')
      .transition()
      .ease(d3.easeElastic)
      .duration(500)
      .attr('d', (d) => {
        return this.arcMouseover(d)
      })
      .on('interrupt', () => {
        circleEl.select('path').attr('d', (d) => {
          return this.arc(d)
        })
      })
      

    // 透明度
    circleEl.style('opacity', 0.3)
    highlightEl.style('opacity', 1)

    // 中間文字
    this._changeCentralInfo(this.centralInfoG, highlightData.data)
    
  }

  // 取消區塊放大動畫
  private _removeHighlight (circleEl, chartEl) {

    circleEl.style('opacity', 1)

    // 取消放大
    circleEl.select('path').transition()
      .attr('d', (d) => {
        return this.arc(d)
      })
    
    this._changeCentralInfo(this.centralInfoG, this.maxData)
  }

  // 繪製圓餅圖
  private _renderPie (selection, t = 1) {
    // layout
    let pie = d3.pie()
      .startAngle(0)
      .endAngle(Math.PI * t * 2)
      .value(d => {
        return d.value
      })
      .sort((a, b) => {
        return b.value - a.value
      })
      // .sort(d3.ascending)
    let pieData = pie(this.dataSet)

    // 塞入百分比資料
    pieData = pieData.map(d => {
      d.data.percent = (Number(d.value) / this.sumValue * 100).toFixed(1)
      return d
    })

    // console.log(pieData)
    // let update = this.chartG.selectAll('g').data(pieData)
    let update = selection.selectAll('g').data(pieData)
    let enter = update.enter()
    // let exit = update.exit()

    enter
      .append('g')
      .classed('choose__donut-chart__part', true)
      .attr('transform', 'translate(' + this.translateX + ',' + this.translateY + ')')
      .append('path')
      .merge(update)
      .select('path')
      .attr('fill', (d, i) => {
        return this.colorScale(d.data.name)
      })
      .attr('d', d => {
        return this.arc(d)
      })
    // exit.remove()
  }

  public render (data: Array<DonutChartTypes.DonutChartRenderData>): void {
    // const color = d3.scaleOrdinal()
    //   .domain(data.map(d => d.name ))
    //   .range(this.colorSet)
    const sortedData = data.sort((a, b) => {
      return b.value - a.value
    })

    // 總數
    this.sumValue = d3.sum(data, d => d.value)
    // 取得dataSet
    this.dataSet = sortedData.map(d => {
      const percent = Number(d.value) / this.sumValue * 100
      return {
        name: d.name,
        value: d.value,
        percent,
        countText: utils.toCurrency(d.value) + '次',
        percentText: utils.toPercentString(percent, 0)
      }
    })
    // 最大值
    this.maxData = this.dataSet.reduce((last, current) => {
      if (current.value > last.value) {
        return current
      } else{
        return last
      }
    }, { value: -1 })

    // 底部選單
    let listData = this.dataSet.map(d => {
      return {
        emotion: d.name,
        count: d.value,
        percent: d.percent
      }
    })
    this.buttomList.render(listData)

    // 清空原有內容
    this.centralInfoG.selectAll('text').remove()
    this.centralInfoG.selectAll('svg').remove()
    this.chartG.selectAll('g').remove()    
    
    // 甜甜圈圖動畫
    this.chartG
      .transition()
      .duration(800)
      .tween('move', (d, i, nodes) => {
        return (t) => {
          this._renderPie(this.chartG, t)
        }
      })
      .on('end', (d, i, nodes) => {
        this._renderPie(this.chartG)

        const g = this.chartG.selectAll('g.choose__donut-chart__part')

        // -- 中間顯示最多的情緒資料 --
        // 初始化中間資訊（第一名）
        this._initCentralInfo(this.centralInfoG, this.maxData)
        
        // g.append('text')
        //   .attr('transform', (d) => {
        //     let percent = Number(d.value) / this.sumValue * 100
        //     let x = this.arc.centroid(d)[0] * 1
        //     let y = this.arc.centroid(d)[1] * 1
        //     if(percent < 6){
        //       x *= 1.2;
        //       y *= 1.2;
        //     }
        //     return 'translate(' + x + ',' + y+ ')'
        //   })
        //   .attr('text-anchor', 'middle')
        //   .attr('font-size', '13')
        //   .style('pointer-events', 'none')
        //   .text((d) => {
        //     let percent = Number(d.value) / this.sumValue * 100
        //     return percent.toFixed(1) + '%'
        //   })
        // -- 各別區塊 --
        g.each((d, i, all) => {
          let percent = Number(d.value) / this.sumValue * 100

          if (percent > 3.5) {
            let iconEl = d3.select(all[i])
            let x = this.arc.centroid(d)[0] * 1
            let y = this.arc.centroid(d)[1] * 1
            new ReactionIcon(iconEl, d.data.name, {
              x: x - 9,
              y: y - 9,
              width: 18,
              height: 18
            })
          }
        })

        g.on('mouseover', (d, i, all) => {
          // // tooltip
          // // let x = d3.event.pageX
          // // let y = d3.event.pageY
          // let x = this.arc.centroid(d)[0] + this.translateX + 50
          // let y = this.arc.centroid(d)[1] + this.translateY - 20
          // let percent = (Number(d.value) / this.sumValue * 100).toFixed(1) + '%'
          // tooltip = new modules.Tooltip(el, x, y, {
          //   title: d.data.name,
          //   count: d.data.value,
          //   rate: percent
          // })
          
          // 區塊放大動畫
          this._setHighlight(g, d3.select(all[i]))
          
          // 底部選單連動
          this.buttomList.active(d.data.name)

        })
        // .on('mousemove', function(e) {
        //   if(tooltip != null) {
        //     let x = d3.event.pageX
        //     let y = d3.event.pageY
        //     tooltip.move(x, y)
        //     console.log(e)
        //     console.log(x + ',' + y)
        //   }
        // })
        .on('mouseout', (d, i, all) => {
          // if(tooltip != null) {
          //   tooltip.remove()
          // }

          // 取消區塊放大動畫
          this._removeHighlight(g, this.chartG)
          // 底部選單回復
          this.buttomList.removeActive()
        })

        // 底部選單連動
        this.buttomList.onMouseover((item) => {
          let highlightEl = g.filter(d => {
            return d.data.name === item.emotion
          })
          // 區塊放大動畫
          this._setHighlight(g, highlightEl)
          // 底部選單連動
          this.buttomList.active(item.emotion)

        })
        this.buttomList.onMouseout(() => {
          // 取消區塊放大動畫
          this._removeHighlight(g, this.chartG)
          // 底部選單回復
          this.buttomList.removeActive()

        })

      })
      
  }
}
