require('./emotion_percentage_bar.scss')

import * as d3 from 'd3'
import utils from '../../utils/utils.js'

export default class EmotionPercentageBar {
  constructor (el, params) {
    this.width = params.options.width // svg寬度
    this.height = params.options.height // svg寬度

    this.componentEl = el
      .append('div')
      .classed('choose__emotion-percentage-bar', true)
      .attr('width', this.width + 'px')
      .attr('height', this.height + 'px')
    
  }

  render (data) {
    const sumValue = data.positive + data.negative + data.neutral
    // const defaultWidth = this.width / 3
    let negativeRate, negativeWidth, neutralRate, neutralWidth, positiveRate, positiveWidth
    if (sumValue == 0) {
      return // 無資料則不顯示
    }
    negativeRate = data.negative / sumValue
    negativeWidth = negativeRate * this.width
    neutralRate = data.neutral / sumValue
    neutralWidth = neutralRate * this.width
    positiveRate = data.positive / sumValue
    positiveWidth = positiveRate * this.width

    let dataSet = [
      {
        name: 'negative',
        count: data.negative,
        percent: negativeRate * 100,
        width: negativeWidth,
        left: 0
      },
      {
        name: 'neutral',
        count: data.neutral,
        percent: neutralRate * 100,
        width: neutralWidth,
        left: negativeWidth
      },
      {
        name: 'positive',
        count: data.positive,
        percent: positiveRate * 100,
        width: positiveWidth,
        left: negativeWidth + neutralWidth
      }
    ]
    
    let update = this.componentEl
      .selectAll('div')
      .data(dataSet)
    let enter = update.enter()
      .append('div')
      .attr('class', d => {
        return 'choose--' + d.name
      })
      .classed('choose__emotion-percentage-bar__block', true)
      .style('width', d => `${d.width}px`)
      .style('left', (d, i, all) => `${d.left}px`)
    let exit = update.exit()
    let merge = enter.merge(update)
    enter
      .append('span')
      .classed('choose__emotion-percentage-bar__text', true)
    merge
      .select('.choose__emotion-percentage-bar__text')
      .text(d => {
        if (d.width < 30) {
          return '' // 太窄則不顯示
        }
        return utils.toPercentString(d.percent, 0)
      })
  }
}
