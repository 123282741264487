require('./emotion_progress_chart.scss')

import utils from '../../utils/utils'
import * as d3 from 'd3'

export default class ProgressChart {
  constructor (el) {
    let html = '\
      <div class="circle positive">\
        <div class="float-circle">\
          <h6>正面文章</h6>\
          <div class="percent">\
            <div class="number">\
            </div>\
            <div class="icon">%</div>\
          </div>\
        </div>\
        <div class="progress-line">\
          <svg>\
            <path fill="none" stroke="#efc57f" stroke-linecap="round" stroke-width="16" stroke-miterlimit="10" d="M84.696,8c41.965,0.394,75.665,34.729,75.271,76.694c-0.396,41.964-34.728,75.662-76.692,75.27 C41.31,159.57,7.61,125.233,8.003,83.272C8.39,42.153,41.363,8.967,82.17,8.018"></path>\
          </svg>\
        </div>\
      </div>\
      <div class="circle neutral">\
        <div class="float-circle">\
          <h6>中立文章</h6>\
          <div class="percent">\
            <div class="number">\
            </div>\
            <div class="icon">%</div>\
          </div>\
        </div>\
        <div class="progress-line">\
          <svg>\
            <path fill="none" stroke="#a0a0a0" stroke-linecap="round" stroke-width="16" stroke-miterlimit="10" d="M70.58,8c34.241,0.321,61.739,28.338,61.418,62.58c-0.323,34.241-28.336,61.737-62.577,61.417 C35.18,131.676,7.683,103.658,8.003,69.42C8.318,35.868,35.223,8.789,68.52,8.014"></path>\
          </svg>\
        </div>\
      </div>\
      <div class="circle negative">\
        <div class="float-circle">\
          <h6>負面文章</h6>\
          <div class="percent">\
            <div class="number">\
            </div>\
            <div class="icon">%</div>\
          </div>\
        </div>\
        <div class="progress-line">\
          <svg>\
            <path fill="none" stroke="#587791" stroke-linecap="round" stroke-width="16" stroke-miterlimit="10" d="M84.696,8c41.965,0.394,75.665,34.729,75.271,76.694c-0.396,41.964-34.728,75.662-76.692,75.27 C41.31,159.57,7.61,125.233,8.003,83.272C8.39,42.153,41.363,8.967,82.17,8.018"></path>\
          </svg>\
        </div>\
      </div>\
    '

    this.componentEl = el
      .append('div')
      .classed('choose__emotion-progress-chart', true)
      .html(html)
  }
  render (data) {
    
    let total = data.positive.count + data.negative.count + data.neutral.count
    let positivePercent = (data.positive.count / total) * 100
    if (isNaN(positivePercent)) positivePercent = 0
    let negativePercent = (data.negative.count / total) * 100
    if (isNaN(negativePercent)) negativePercent = 0
    let neutralPercent = (data.neutral.count / total) * 100
    if (isNaN(neutralPercent)) neutralPercent = 0

    let positivePercentEl = this.componentEl.select('.positive .percent .number')
    let neutralPercentEl = this.componentEl.select('.neutral .percent .number')
    let negativePercentEl = this.componentEl.select('.negative .percent .number')
    this.componentEl.transition()
      .duration(800)
      .tween('move', (t) => {
        return (t) => {
          let positive = ((isNaN(positivePercent) ? 0 : positivePercent) * t).toFixed(0)
          let neutral = ((isNaN(neutralPercent) ? 0 : neutralPercent) * t).toFixed(0)
          let negative = ((isNaN(negativePercent) ? 0 : negativePercent) * t).toFixed(0)
          positivePercentEl.text(positive)
          neutralPercentEl.text(neutral)
          negativePercentEl.text(negative)
        }
      })
    

    // 進度線
    var lineBigFull = 480
    var neutralDashAdjust = 0.8;

    var linePositive = this.componentEl.select(".circle.positive .progress-line svg path");
    var lineNeutral = this.componentEl.select(".circle.neutral .progress-line svg path");
    var lineNegative = this.componentEl.select(".circle.negative .progress-line svg path");

    var positiveDash =
      (positivePercent * lineBigFull) / 100 + ", " + lineBigFull;
    var neutralDash =
      (neutralPercent * lineBigFull * neutralDashAdjust) / 100 +
      ", " +
      lineBigFull;
    var nagetiveDash =
      (negativePercent * lineBigFull) / 100 + ", " + lineBigFull;

    let addAnimateStyle = () => {
      // css動畫
      linePositive.style("stroke-dasharray", positiveDash);
      lineNeutral.style("stroke-dasharray", neutralDash);
      lineNegative.style("stroke-dasharray", nagetiveDash);
    }
      
    if (utils.isIEBrowser() === true) {
      addAnimateStyle() // 不加setTimeout無動畫效果
    } else {
      setTimeout(() => { addAnimateStyle() }, 100)
    }
    
    
  }
}
