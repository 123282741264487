require('./reaction_item_list.scss')

// import d3Selection from 'd3-selection'
// const d3 = {
//   select,
//   event
// }
import * as d3 from 'd3'
import utils from '../../utils/utils'
import ReactionIcon from '../ReactionIcon/ReactionIcon'

// mouseover事件callback
var mouseoverCallback = function(){}
var mouseoutCallback = function(){}
var clickCallback = function(){}

export default class ReactionItemList {
  constructor (el) {
    this.listEl = el.append('div').classed('choose__reaction-item-list', true)

  }

  render (data) {
    var update = this.listEl.selectAll('a').data(data)
    var enter = update.enter()
    var exit = update.exit()

    this.merge = enter
      .append('a')
      .classed('choose__reaction-item-list__item', true)
      .html(d => {
        var t =
          '<span class="choose__reaction-item-list__icon"></span>\
          <span>\
            <span class="choose__reaction-item-list__count"></span><span class="choose__reaction-item-list__unit">次</span><br>\
            <span class="choose__reaction-item-list__percent">\
          </span>'
        return t
      })
      .merge(update)

    // 次數
    this.merge.select('.choose__reaction-item-list__count')
      .text(d => utils.toCurrency(d.count))
    // 百分比
    this.merge.select('.choose__reaction-item-list__percent')
      .text(d => utils.toPercentString(d.percent, 1))
      
    this.merge.on('mouseover', (d, i, all) => {
        // this.merge.classed('active', false)
        // var item = d3.select(all[i])
        // item.classed('active', true)
        mouseoverCallback(d, i, all)
      })
      .on('mouseout', (d, i, all) => {
        // this.merge.classed('active', false)
        mouseoutCallback(d, i, all)
      })
      .on('click', (d, i, all) => {
        clickCallback(d, i, all)
        d3.event.stopPropagation()
      })
    
    // icon
    this.merge
      .each((d, i, all) => {
        let itemEl = d3.select(all[i])
        let iconEl = itemEl.selectAll('.choose__reaction-item-list__icon')
        iconEl.html('')
        new ReactionIcon(iconEl, d.emotion, {
          x: 0,
          y: 0,
          width: 18,
          height: 18
        })
      })
  }
  
  active (name) {
    this.merge 
      .each((d, i, all) => {
        if (d.emotion === name) {
          d3.select(all[i]).classed('semitransparent', false)
        } else {
          d3.select(all[i]).classed('semitransparent', true)
        }
      })
  }
  removeActive () {
    this.merge.classed('semitransparent', false)
  }
  onMouseover (callback) {
    mouseoverCallback = callback
  }
  onMouseout (callback) {
    mouseoutCallback = callback
  }
  onClick (callback) {
    clickCallback = callback
  }
}
