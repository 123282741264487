import Mock from 'mockjs'

// /api/get-pages-info/
Mock.mock(/api\/test-fanpage-social-reaction/, Mock.mock({
  result: true,
  payload: {
    influentialPost: {
      url: 'http://xxx',
      text: '謝謝小英總統，這場運動以來您總是及時發聲支持香港，香港人處於一個困難的時期。這些天我幾乎和我認識的每一個台灣朋友同事都講，你們明年一月一定要回去投票，腦袋一定要清醒！守住台灣的民主和價值，因為得來不容易，失去很...',
      score: 4.5
    },
    topUsers: [
      {
        name: 'Jacky Wu',
        url: 'http://xxx',
        positive: 105,
        neutral: 233,
        negative: 85
      },
      {
        name: 'Paul Chen',
        url: 'http://xxx',
        positive: 25,
        neutral: 80,
        negative: 15
      },
      {
        name: 'Penny Wong',
        url: 'http://xxx',
        positive: 74,
        neutral: 180,
        negative: 77
      }
    ],
    commentCount: {
      positive: 120,
      neutral: 211,
      negative: 88
    },
    reaction: [
      {
        name: 'like',
        value: 180
      },
      {
        name: 'ha',
        value: 88
      },
      {
        name: 'love',
        value: 70
      },
      {
        name: 'wow',
        value: 58
      },
      {
        name: 'cry',
        value: 15
      },
      {
        name: 'angry',
        value: 18
      }
    ],
    share: 1580,
    totalArticles: 13000
  }
}))
