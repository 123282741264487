require('./bottom_menu.scss')

// import d3Selection from 'd3-selection'
// const d3 = {
//   select,
//   event
// }
import * as d3 from 'd3'

export default class BottomMenu {
  constructor (el, list, defaultValue) {
    let listData = list
    let listEl = el.append('div')
      .attr('id', 'choose__bottom-menu')
    // .classed('choose__bottom-menu', true)
    let update = listEl.selectAll('a').data(listData)
    let enter = update.enter()
    let exit = update.exit()

    // 事件callback
    this.mouseoverCallback = function () {}
    this.mouseoutCallback = function () {}
    this.clickCallback = function () {}

    this.merge = enter.merge(update)
      .append('div')
      .classed('choose__bottom-menu__item', true)
      .classed('active', d => {
        return defaultValue === d.value ? true : false
      })
      .html(d => {
        let t =
          '{{html:icon}}' +
          '<p style="color:{{color}}">{{label}}</p>'
        if (d.icon) {
          t = t.replace('{{html:icon}}', '<i class="' + d.icon + '" style="color:' + d.color + '"></i>')
        } else {
          t = t.replace('{{html:icon}}', '')
        }
        if (d.color) {
          t = t.replace('{{color}}', d.color)
        } else {
          t = t.replace('{{color}}', '#828282')
        }
        t = t.replace('{{label}}', d.label)
        return t
      })
      .on('mouseover', (d, i, all) => {
        // this.merge.classed('active', false)
        // let item = d3.select(all[i])
        // item.classed('active', true)
        this.mouseoverCallback(d, i, all)
      })
      .on('mouseout', (d, i, all) => {
        // this.merge.classed('active', false)
        this.mouseoutCallback(d, i, all)
      })
      .on('click', (d, i, all) => {
        this.clickCallback(d, i, all)
        d3.event.stopPropagation()
      })
  }
  active (name) {
    this.merge.classed('active', false)
    let item = this.merge.filter(d => {
      return d.name === name
    })
    item.classed('active', true)
  }
  removeActive () {
    this.merge.classed('active', false)
  }
  onMouseover (callback) {
    this.mouseoverCallback = callback
  }
  onMouseout (callback) {
    this.mouseoutCallback = callback
  }
  onClick (callback) {
    this.clickCallback = callback
  }
}
