// @ts-nocheck
import * as d3 from 'd3'
import * as codes from '../../utils/codes.js'
import utils from '../../utils/utils.js'
import ChartCard from '../../components/ChartCard/ChartCard'
import ForceDirectedChart from '../../components/ForceDirectedChart/ForceDirectedChart'
import dbsLogoSvgBase64 from '../../assets/base64/dbsLogoSvgBase64.js'
import * as ForceDirectedChartCardTypes from '../../types/charts/forceDirectedChartCard'
import * as ForceDirectedChartTypes from '../../types/components/forceDirectedChart'
// import * as screenShot from '../../utils/screenShot.js'

export default class ForceDirectedChartCard implements ForceDirectedChartCardTypes.IForceDirectedChartCard {
  private dom: d3.Selection<d3.BaseType, any, any, any>
  private rootID: string // 根節點id
  private chartCard: ChartCard
  private chartSelector: d3.Selection<d3.BaseType, any, any, any>
  private forceDirectedChart: ForceDirectedChart
  // 切換截圖模式前的尺寸
  private cardOriginSize: { width: string; height: string } = { width: '', height: '' }
  // 卡片樣式
  private cardStyle: any

  constructor (dom, options) {
    if (!dom || !options) {
      throw new Error('missing necessary parameter');
    }
    this.dom = dom
    
    // -- 放置卡片 --
    const el = d3.select(dom)
    this.cardStyle = options.size == null ? utils.clone(codes.COMPANY_STOCK_RELATIONSHIP_CARD_SIZE[codes.DEFAULT_CARD_SIZE_TYPE]) : codes.COMPANY_STOCK_RELATIONSHIP_CARD_SIZE[options.size]
    this.chartCard = new ChartCard(
      el,
      // {
      //   name: 'company-stock-relationship',
      //   title: options.title,
      //   subtitle: '股權百分比為持有股份/(實收資本額/10)*100%',
      //   watermarkBase64: '',
      //   logoBase64: dbsLogoSvgBase64,
      //   tooltip: `股權結構關係圖為一張展開式的樹狀結構圖表<br>
      //             ，以所查詢之公司做為中心進行運算，方向向<br>
      //             上為公司的【法人】董監事、股東(如有資訊)<br>
      //             ，方向向下為公司的投資對象(即公司擔任其<br>
      //               他單位的法人董事)`,
      //   size: options.size || codes.DEFAULT_CARD_SIZE_TYPE,
      //   style: this.cardStyle
      // }
      options
    )
    this.chartSelector = this.chartCard.chartSelector()
    this.cardOriginSize = {
      width: el.style('width'),
      height: el.style('height')
    }

    // -- 放入圖表 --
    this.forceDirectedChart = new ForceDirectedChart(this.chartSelector, {})
  }

  private doInit ({ nodes, links, expandAll }: {
    nodes: Array<ForceDirectedChartTypes.ChartNode>;
    links: Array<ForceDirectedChartTypes.ChartLink>;
    expandAll: boolean;
  }): void {
    // render
    this.forceDirectedChart.init({ nodes, links, expandAll })
  }


  public init (data: {
      nodes: Array<ForceDirectedChartTypes.ChartNode>;
      links: Array<ForceDirectedChartTypes.ChartLink>;
      expandAll: boolean
  }): void {
    // 觸發顯示圖表動畫
    utils.addScrollTrigger(this.dom, () => this.doInit(data))
  }

  public resetNodes (data: {
    nodes: Array<ForceDirectedChartTypes.ChartNode>;
    links: Array<ForceDirectedChartTypes.ChartLink>;
  }): void {
    this.forceDirectedChart.resetNodes({ nodes, links })
  }

  public setTitle (title): void {
    this.chartCard.setTitle(title)
  }

  public setStyle (returnStyle) {
    this.forceDirectedChart.setStyle(returnStyle)
  }

  public onNodeClick (callback): void {
    this.forceDirectedChart.onNodeClick((d) => {
      // // 禁止點擊：非公司、或為根節點、或無可展開的父節點
      // if (!d.uniID || d.id === this.rootID || d.hasTop === false) {
      //   return
      // }
      callback(d)
    })
  }

  public onExtandBtnClick (callback): void {
    this.forceDirectedChart.onExtandBtnClick((d) => {
      callback(d)
    })
  }

  public toggleTopNodes (id: string): void {
    this.forceDirectedChart.toggleTopNodes(id)
  }

  public resize () {
    // 置中
    this.forceDirectedChart.resize()
  }

  public async screenShotMode (isScreenShot: boolean): void {
    if (isScreenShot) {
      this.forceDirectedChart.forceStop()
      // 取得寬高
      const screenShotSize = this.forceDirectedChart.getChartSize()
      // 將卡片寬高設為和符合圖表尺寸（圖表寬高加上卡片間距）
      let width = screenShotSize.width + this.cardStyle['padding-left'] + this.cardStyle['padding-right']
      let height = screenShotSize.height + this.cardStyle['padding-top'] + this.cardStyle['padding-bottom'] + this.cardStyle.header.height + this.cardStyle.footer.height + 20
      width = width * 1.15
      height = height * 1.15
      if (width < 640) {
        width = 640
      }
      if (height < 480) {
        height = 480
      }
      this.chartCard.setCardSize({
        width: `${width}px`,
        height: `${height}px`
      })
      // 置中
      this.forceDirectedChart.resize()
    } else {
      // 將卡片寬高還原
      this.chartCard.setCardSize({
        width: this.cardOriginSize.width,
        height: this.cardOriginSize.height
      })
      // 置中
      this.forceDirectedChart.resize()

      this.forceDirectedChart.forceRestart()
    }

  }
}