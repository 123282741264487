require('./reaction_tooltip.scss')

// import { select, selectAll, update, remove, merge, data, attr, classed, style, html } from 'd3-selection'
// const d3 = {
//   select
// }
import * as d3 from 'd3'
import utils from '../../utils/utils.js'
import ReactionIcon from '../ReactionIcon/ReactionIcon.js'

let tipContainerEl

export default class ReactionTooltip {
  constructor (containerEl, content, x, y) {
    tipContainerEl = containerEl
    tipContainerEl.classed('choose__tooltip-container', true)
    let tooltipEl = tipContainerEl
      .append('div')
      .classed('choose__tooltip', true)
    if (x != null && y != null) {
      tooltipEl
        .style('position', 'fixed')
        .style('z-index', 10000)
        .style('left', x + 'px')
        .style('top', y + 'px')
    } else {
      tooltipEl
        .style('position', 'absolute') // 如無設定座標使用absolute
        .style('z-index', 10000)
        .style('top', '20px')
        .style('left', '30px')
    }
    let iconEel = tooltipEl.append('div').classed('choose__reaction-tooltip__icon', true)
    new ReactionIcon(iconEel, content.reaction, {
      // x: padding.left + this.xScale(d.name) - 9,
      // y: this.height - padding.bottom,
      width: 18,
      height: 18
    })
    tooltipEl
      .append('div')
      .classed('choose__reaction-tooltip__text-box', true)
      .html('<div class="choose__reaction-tooltip__count">' + content.count + '次</div>' +
      '<div class="choose__reaction-tooltip__percent">' + utils.toPercentString(content.percent, 1, '%') + '</div>')
  }
  move (x, y) {
    d3.select('.choose__tooltip-container .choose__tooltip').style('left', x + 'px').style('top', y + 'px')
    // d3.select('#choose__tooltip').style('transform', 'translate(' + x + ',' + y + ')')
  }
  remove () {
    let el = d3.selectAll('.choose__tooltip-container .choose__tooltip').html('')
    el.remove()
    tipContainerEl.classed('choose__tooltip-container', false)
  }
}
