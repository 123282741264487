require('./number_tooltip.scss')

// import { select, selectAll, update, remove, merge, data, attr, classed, style, html } from 'd3-selection'
// const d3 = {
//   select
// }
import * as d3 from 'd3'
// import ReactionIcon from '../ReactionIcon/ReactionIcon'

let tipContainerEl

export default class NumberTooltip {
  constructor (containerEl, content, x, y) {
    tipContainerEl = containerEl
    tipContainerEl.classed('choose__tooltip-container', true)

    const unit = content.unit || '則'

    let tooltipEl = tipContainerEl
      .append('div')
      .classed('choose__tooltip', true)
    if (x != null && y != null) {
      tooltipEl
        .style('position', 'fixed')
        .style('z-index', 10000)
        .style('left', x + 'px')
        .style('top', y + 'px')
    } else {
      tooltipEl
        .style('position', 'absolute') // 如無設定座標使用absolute
        .style('z-index', 10000)
        .style('top', '20px')
        .style('left', '30px')
    }
    tooltipEl
      .append('div')
      .classed('choose__number-tooltip__title', true)
      .text(content.title)
    
    let textEl = tooltipEl
      .append('div')
      .classed('choose__number-tooltip__text-box', true)
    
    textEl
      .append('div')
      .classed('choose__number-tooltip__count', true)
      .text(content.count + unit)
    if (content.percent != null) {
      textEl
        .append('div')
        .classed('choose__number-tooltip__percent', true)
        .text(content.percent.toFixed(0) + '%')
    }
  }
  move (x, y) {
    d3.select('.choose__tooltip-container .choose__tooltip').style('left', x + 'px').style('top', y + 'px')
    // d3.select('#choose__tooltip').style('transform', 'translate(' + x + ',' + y + ')')
  }
  remove () {
    let el = d3.selectAll('.choose__tooltip-container .choose__tooltip').html('')
    el.remove()
    tipContainerEl.classed('choose__tooltip-container', false)
  }
}
