// @ts-nocheck
// require('./dagre_directed_chart.scss')

import * as d3 from 'd3'
import dagreD3 from 'dagre-d3'
// import utils from '../../utils/utils.js'
import d3Utils from '../../utils/d3Utils.js'
import * as DagreDirectedChartTypes from '../../types/components/dagreDirectedChart'
import Tooltip from '../Tooltip/Tooltip'

// 按鈕icon樣式
const fnIconSize = 16
const fnIconActiveSize = 24
const fnIconActiveOffset = fnIconActiveSize / 2

// 新增節點遞迴（目前只展開一層所以無遞迴）
function addLoop (
  { nodes, edges }: { nodes: Array<DagreDirectedChartTypes.ChartNode>; edges: Array<DagreDirectedChartTypes.ChartEdge>;},
  { nodes: allNodes, edges: allEdges, _NodesMap }: { nodes: Array<DagreDirectedChartTypes.ChartNode>; edges: Array<DagreDirectedChartTypes.ChartEdge>; _NodesMap: Map<string, any>; },
  originID: string): {
    nodes: Array<DagreDirectedChartTypes.ChartNode>;
    edges: Array<DagreDirectedChartTypes.ChartEdge>;
  } {
  const rootID = allNodes[0].id
  // step1 新增關聯edges
  let findIDs = []
  for (let i in allEdges) {
    let newNode = null
    if (allEdges[i]._end === originID) {
      newNode = allEdges[i]._start
    // } else if (allEdges[i]._start === originID) {
    //   newNode = allEdges[i]._end
    } else {
      continue
    }
    let isExist = edges.some((d: DagreDirectedChartTypes.ChartEdge) => d._start === allEdges[i]._start && d._end === allEdges[i]._end)
    if (isExist === true) {
      continue
    }
    let newEdge = JSON.parse(JSON.stringify(allEdges[i])) // 要用深拷貝不然有bug
    edges.push(newEdge)
    findIDs.push(newNode)
  }
  if (findIDs.length < 1) {
    return { nodes, edges }
  }
  // step2 如果start-nodes無存在目前nodes則新增進來
  let findNoneNodeIDs = []
  let addNodes: Array<DagreDirectedChartTypes.ChartNode> = []
  for (let testID of findIDs) {
    let isExist = nodes.some(d => d.id === testID)
    if (isExist === false) {
      let findNode = _NodesMap.get(testID)
      if (findNode) {
        addNodes.push(findNode)
        findNoneNodeIDs.push(findNode.id)
      }
    }
  }
  // step3 修正展開狀態
  addNodes = addNodes.map((node: DagreDirectedChartTypes.ChartNode) => {
    let hasTop = allEdges.some(edge => {
      // 非node的上層
      if (edge._end !== node.id) {
        return false
      }
      // 如果上層為根節點則無展開按鈕
      if (edge._start === rootID) {
        return false
      }
      // 有上層且非根節點
      return true
    })
    if (hasTop) {
      node.hasTop = true
      node.isTopExpanded = false // 預設false
    }
    return node
  })
  nodes = nodes.concat(addNodes)
  // step3 用前步驟新增的nodes再往子node新增
  // for (let nextID of findNoneNodeIDs) {
  //   addLoop(nodes, edges, allNodes, allEdges, nextID)
  // }
  return { nodes, edges }
}
// 刪除節點遞迴
function deleteLoop (
  { nodes, edges }: { nodes: Array<DagreDirectedChartTypes.ChartNode>; edges: Array<DagreDirectedChartTypes.ChartEdge> },
  { nodes: allNodes, edges: allEdges }: { nodes: Array<DagreDirectedChartTypes.ChartNode>; edges: Array<DagreDirectedChartTypes.ChartEdge>; },
  originID): {
    nodes: Array<DagreDirectedChartTypes.ChartNode>;
    edges: Array<DagreDirectedChartTypes.ChartEdge>;
  } {
  const rootID = allNodes[0].id
  // let newData = { nodes, edges }
  let newNodes = []
  let newEdges = []
  // step1 刪除關聯edges
  let findIDs = []
  for (let i in edges) {
    if (edges[i]._end === originID && edges[i]._start !== rootID) {
      // 取得點擊對象的上游（如為根節點不移除）
      findIDs.push(edges[i]._start)
    // } else if (edges[i]._start === originID
    // && edges.some(e => e._end == edges[i]._end && e._start == rootID)) {
    //   // 如點擊對象的下游是根節點的下游，取得點擊對象的下游
    //   findIDs.push(edges[i]._end)
    } else {
      // 不刪除
      newEdges.push(edges[i])
    }
  }
  // step2 過濾掉無連接到根節點的線
  const filterEdgesFromRoot = (edges) => {
    let newEdges = []
    // const searchUp = (_start:string, _end:string) => {
    //   const upEdges = edges.filter(edge => _start === edge._end) // 上一層節點的連接線
    //   for (const edge of upEdges) {
    //     let isExist = newEdges.some(newEdge => {
    //       return newEdge._start === edge._start && newEdge._end === edge._end
    //     })
    //     if (isExist == false) {
    //       newEdges.push(edge)
    //       searchUp(edge._start, edge._end)
    //     }
    //   }
    // }
    // const topFromRoot = edges.filter(edge => edge._end === rootID)
    // for (const edge of topFromRoot) {
    //   newEdges.push(edge)
    //   searchUp(edge._start, edge._end)
    // }
    // const searchDown = (_start:string, _end:string) => {
    //   const downEdges = edges.filter(edge => _end === edge._start) // 下一層節點的連接線
    //   for (const edge of downEdges) {
    //     let isExist = newEdges.some(newEdge => {
    //       return newEdge._start === edge._start && newEdge._end === edge._end
    //     })
    //     if (isExist == false) {
    //       newEdges.push(edge)
    //       searchDown(edge._start, edge._end)
    //     }
    //   }
    // }
    // const downFromRoot = edges.filter(edge => edge._start === rootID)
    // for (const edge of downFromRoot) {
    //   let isExist = newEdges.some(newEdge => {
    //     return newEdge._start === edge._start && newEdge._end === edge._end
    //   })
    //   if (isExist == false) {
    //     newEdges.push(edge)
    //   }
    //   searchDown(edge._start, edge._end)
    // }
    const searchEdge = (currentID:string) => {
      const topFromCurrent = edges.filter(edge => edge._end === currentID)
      const downFromCurrent = edges.filter(edge => edge._start === currentID)
      for (const edge of topFromCurrent) {
        let isExist = newEdges.some(newEdge => {
          return newEdge._start === edge._start && newEdge._end === edge._end
        })
        if (isExist == false) {
          newEdges.push(edge)
          searchEdge(edge._start)
        }
      }
      for (const edge of downFromCurrent) {
        let isExist = newEdges.some(newEdge => {
          return newEdge._start === edge._start && newEdge._end === edge._end
        })
        if (isExist == false) {
          newEdges.push(edge)
          searchEdge(edge._end)
        }
      }
    }
    searchEdge(rootID)

    return newEdges
  }
  newEdges = filterEdgesFromRoot(newEdges)
  // step3 過濾掉無連接線的節點
  newNodes = nodes.filter((node:DagreDirectedChartTypes.ChartNode) => {
    return newEdges.find((edge:DagreDirectedChartTypes.ChartEdge) => node.id === edge._start || node.id === edge._end) != null
  })

  // step2 如果start-node已無edge則刪除該node
  // let findNoneEdgeIDs = []
  // for (let testID of findIDs) {
  //   let testFilter = edges.find(d => {
  //     return d._start === testID // 該node為上游的連接線
  //       // || (d._start === rootID && d._end === testID) // 該node為根節點的下游
  //       || d._end === testID
  //   })
  //   if (testFilter == null) {
  //     findNoneEdgeIDs.push(testID)
  //     // @Q@ 刻意讓泡泡飛走所以不移除節點，如果要移除的話就把下面這行解開
  //     // nodes = nodes.filter(d => d.id !== testID)
  //   }
  // }
  // @Q@ 後來發現原先做法無法找出所有子節點（漏掉了同時是上游也是下游的情況），所以改為刻意不移除節點的做法
  // step3 用前步驟被刪除的nodes再去往子node刪
  // for (let nextID of findNoneEdgeIDs) {
  //   allNodes = allNodes.map(d => {
  //     if (d.id === nextID) {
  //       // 展開狀態關閉
  //       d.isTopExpanded = false
  //     }
  //     return d
  //   })
  //   newData = deleteLoop(nodes, edges, allNodes, allEdges, nextID)
  //   nodes = newData.nodes
  //   edges = newData.edges
  // }
  return {
    nodes: newNodes,
    edges: newEdges
  }
}

function getExpendIconX (rectWidth) {
  return (rectWidth / 2) + 5
}

export default class DagreDirectedChart implements DagreDirectedChartTypes.IDagreDirectedChart {
  private dagreD3Render = new dagreD3.render()
  private el: d3.Selection<d3.BaseType, any, any, any>
  private svg: d3.Selection<SVGAElement, any, any, any>
  private svgGroup: d3.Selection<SVGAElement, any, any, any>
  private chartG = null
  // 全部的資料（包含未展開的）
  private allData = {
    nodes: [],
    edges: [],
    _NodesMap: null // 將nodes轉為key/value格式
  }
  // 顯示的資料（不包含未展開的）
  private showData = {
    nodes: [],
    edges: [],
    _NodesMap: null // 將nodes轉為key/value格式
  }
  private nodeClickCallback: (arg: any) => void = function () { return null }
  private extandBtnClickCallback: (arg: any) => void = function () { return null }
  private tooltip: Tooltip = null
  private zoom = null
  private direction = 'RL'
  private styleConfig = {
    styles: {
      text: 'fill: #303133;font-weight: normal;font-size: 14px;',
      textRoot: 'fill: #1778f5;font-weight: bold;font-size: 14px;',
      pathTop: 'stroke: #909399; stroke-width: 1.5px; fill:none;',
      pathDown: 'stroke-dasharray: 5; stroke: #909399; stroke-width: 1.5px; fill:none;',
      arrowTop: 'fill: #909399',
      arrowDown: 'fill: #909399',
      rectRoot: 'rx:5px;ry:5px;stroke: url(#gradient);stroke-width: 3px;fill: #fff;',
      rect: 'rx:5px;ry:5px;stroke: #909399;stroke-width: 1.5px;fill: #fff;',
      expandBtn: 'fill:#1778F5'
    },
    _StylesMap: null // styles的map
  }
  //<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 511.993 511.993" style="enable-background:new 0 0 511.993 511.993;" xml:space="preserve">
  private plusSvgHtml = this.returnPlusSvgHtml()
  private minusSvgHtml = this.returnMinusSvgHtml()
  private rootID = ''

  constructor (el: d3.Selection<d3.BaseType, any, any, any>, props: DagreDirectedChartTypes.ChartProps) {
    this.el = el
    const { width, height } = this.getContainerSize()
    this.svg = el
      .append('svg')
      .classed('choose__dagre-directed-chart', true)
      .attr('width', width)
      .attr('height', height)
      // .attr('width', '100%')
      // .attr('height', '100%')
    this.svgGroup = this.svg.append('g').classed('choose__dagre-directed-chart__g', true)

    // this.svg.html(`
    // <linearGradient id="gradient">
    //   <stop offset="0%" style="stop-color: red" />
    //   <stop offset="100%" style="stop-color: yellow" />
    // </linearGradient>
    // `)
    let linearGradient = this.svg
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradient')
    linearGradient
      .append('stop')
      .attr('offset', '0%')
      .style('stop-color', '#81c4c3')
      // .style('stop-color', '#2499FF')
    linearGradient
      .append('stop')
      .attr('offset', '100%')
      .style('stop-color', '#2f8cbf')
      // .style('stop-color', '#004DB1')

    // 初始化styleConfig
    this.setStyle(styleConfig => styleConfig)
  }

  private returnPlusSvgHtml (style='fill:#1778f5') {
    return `
    <circle style="${style};" cx="255.996" cy="255.996" r="255.996"/>
    <polygon style="fill:#ffffff;" points="443.811,297.202 443.811,214.798 297.202,214.798 297.202,68.189 214.799,68.189   214.798,214.798 68.189,214.799 68.189,297.202 214.798,297.202 214.798,443.811 297.201,443.811 297.202,297.202 "/>
    `
  }

  private returnMinusSvgHtml (style='fill:#1778f5') {
    return `
    <circle style="${style};" cx="255.996" cy="255.996" r="255.996"/>
    <rect x="81.679" y="214.535" style="fill:#ffffff;" width="348.646" height="82.922"/>
    `
  }

  private getContainerSize (): { width: number; height: number; x: number; y: number } {
    // 取得圖表container (div)的尺寸
    try {
      const node = this.el.node()
      return node.getBoundingClientRect()
    } catch (e) {
      throw(e)
    }
  }

  // 設置拖拽及放大縮小
  private setZoom (xCenterOffset = 0, yCenterOffset = 0): void {
    // 滑鼠滾動放大縮小
    this.svg.on('zoom', null)
    this.zoom = d3.zoom().on('zoom', () => {
      // this.svgGroup.attr('transform', d3.event.transform)
      // 偏移的座標由中心點計算
      this.svgGroup.attr('transform', `translate(
        ${d3.event.transform.x + (xCenterOffset * d3.event.transform.k)},
        ${d3.event.transform.y + (yCenterOffset * d3.event.transform.k)}
      ) scale(
        ${d3.event.transform.k}
      )`)
    })
    this.svg.call(this.zoom)

  }

  private render ({ nodes = [], edges = [], direction = 'RL'}: {
    nodes: Array<DagreDirectedChartTypes.ChartNode>;
    edges: Array<DagreDirectedChartTypes.ChartEdge>;
    direction: string;
  }): void {
    if (!nodes[0]) {
      return
    }
    this.rootID = nodes[0].id
    // 建立dagreD3
    this.chartG = new dagreD3.graphlib.Graph()
      .setGraph({
        rankdir: direction // 方向右至左
      })
      .setDefaultEdgeLabel(function() { return {}; })
    // 設置nodes
    nodes.forEach(d => {
      this.chartG.setNode(d.id, {
        label: d.label,
        labelStyle: d.id === this.rootID ? this.styleConfig._StylesMap.get('textRoot') : this.styleConfig._StylesMap.get('text'),
        style: this.styleConfig._StylesMap.get(d.style)
      })
    })

    // 設置edges
    edges.forEach(d => {
      let style = d.style.path ? this.styleConfig._StylesMap.get(d.style.path)
        : (d.direction === 'top' ? this.styleConfig._StylesMap.get('pathTop') : this.styleConfig._StylesMap.get('pathDown'))
      let arrowheadStyle = d.style.arrow ? this.styleConfig._StylesMap.get(d.style.arrow)
        : (d.direction === 'top' ? this.styleConfig._StylesMap.get('arrowTop') : this.styleConfig._StylesMap.get('arrowDown'))
      this.chartG.setEdge(d.start, d.end, {
        label: d.label,
        style,
        labelStyle: this.styleConfig._StylesMap.get('text'),
        arrowheadStyle,
        // curve: d3.curveLinear
        curve: d3.curveBundle
        // curve: d3.curveBasis 
        // curve: d3.curveStep
        // curve: d3.curveBundle
        // curve: d3.curveMonotoneY,
      })
    })

    // 繪圖
    // console.log({ nodes, edges, showData: this.showData })
    this.dagreD3Render(this.svgGroup, this.chartG)

    this.resize()

    const nodesG = this.svgGroup.selectAll('g.node')

    // 增加展開icon
    nodesG.each((d: string, i, all) => {
      let g = d3.select(all[i])
      const node = this.showData._NodesMap.get(d)
      if (node && node.hasTop) {
        let iconX = 100
        try {
          iconX = getExpendIconX(g.select('rect').attr('width'))
        } catch (e) { console.error(e) }
        let gSvgClassName = ''
        let svgContent = ''
        if (node.isTopExpanded === true) {
          gSvgClassName = 'bp__minus-btn'
          svgContent = this.minusSvgHtml
        } else {
          gSvgClassName = 'bp__plus-btn'
          svgContent = this.plusSvgHtml
        }
        g.selectAll('.bp__plus-btn').remove()
        g.selectAll('.bp__minus-btn').remove()
        const gSvg = g.append('svg')
          .classed(gSvgClassName, true)
          .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
          .attr('xmls', 'http://www.w3.org/2000/svg')
          .attr('version', '1.1')
          .attr('width', fnIconSize)
          .attr('height', fnIconSize)
          .attr('viewBox', '0 0 511.993 511.993')
          .attr('x', iconX)
          .attr('y', -8)
        gSvg
          .on('click', (id: string) => {
            const item = this.allData._NodesMap.get(id)
            this.extandBtnClickCallback(item)
          })
          .on('mouseover', (d, i, all) => {
            d3.select(all[i])
              .transition()
              .duration(50)
              .attr('width', fnIconActiveSize)
              .attr('height', fnIconActiveSize)
              .attr('y', -fnIconActiveOffset)
          })
          .on('mouseout', (d, i, all) => {
            d3.select(all[i])
              .transition()
              .delay(100)
              .duration(100)
              .attr('width', fnIconSize)
              .attr('height', fnIconSize)
              .attr('y', -8)
          })
        d3Utils.svgHtml(gSvg, svgContent)
      }
    })
    
    // 設滑鼠移過事件
    nodesG
      .select('.label-container')
      .on('click', (id: string) => {
        const item = this.allData._NodesMap.get(id)
        this.nodeClickCallback(item)
      })
      .on('mouseover', (d:string, i, all) => {
        // tooltip
        const content = this.showData._NodesMap.get(d).tooltip
        if (content) {
          const x = d3.event.clientX + 20
          const y = d3.event.clientY + 20
          this.tooltip = new Tooltip(this.el, content, x, y, true)
        }
      })
      .on('mousemove', d => {
        // tooltip
        if(this.tooltip != null) {
          const x = d3.event.clientX + 20
          const y = d3.event.clientY + 20
          this.tooltip.move(x, y)
        }
      })
      .on('mouseout', (d, i, all) => {
        // tooltip
        if(this.tooltip != null) {
          this.tooltip.remove()
        }
      })
    
    // 取消文字指標
    nodesG.selectAll('tspan').attr('pointer-events', 'none')

    // 初始化
    // if (init === true) {
      // 置中
      // let xCenterOffset = (Number(this.svg.attr('width')) - this.chartG.graph().width) / 2
      // this.svgGroup.attr("transform", "translate(" + xCenterOffset + ", 20) scale(1)")
      // // this.svgGroup.attr('x', xCenterOffset)
      // // 滑鼠滾動放大縮小
      // let zoom = d3.zoom().on('zoom', () => {
      //   // this.svgGroup.attr('transform', d3.event.transform)
      //   // 偏移的座標由中心點計算
      //   this.svgGroup.attr('transform', `translate(
      //                                       ${d3.event.transform.x + (xCenterOffset * d3.event.transform.k)},
      //                                       ${d3.event.transform.y + (20 * d3.event.transform.k)}
      //                                     ) scale(
      //                                       ${d3.event.transform.k}
      //                                     )`)
      // })
      // this.svg.call(zoom)
      // this.resize()
    // }

    // // 設置事件監聽
    // this.svgGroup.selectAll('g.node')
    //   .on('click', (id) => {
    //     let item = this.allData.nodes.find(d => d.id === id)
    //     this.nodeClickCallback(item)
    //   }) 
  }

  public setStyle (returnStyle): void {
    const newStyleConfig = returnStyle(this.styleConfig)
    // 建立_StylesMap
    let NewStylesMap = new Map()
    Object.keys(newStyleConfig.styles).forEach(key => {
      const value = newStyleConfig.styles[key]
      NewStylesMap.set(key, value)
    })
    newStyleConfig._StylesMap = NewStylesMap
    this.styleConfig = newStyleConfig
    // 設定展開按鈕顏色
    this.plusSvgHtml = this.returnPlusSvgHtml(this.styleConfig._StylesMap.get('expandBtn'))
    this.minusSvgHtml = this.returnMinusSvgHtml(this.styleConfig._StylesMap.get('expandBtn'))
  }

  public onNodeClick (callback): void {
    this.nodeClickCallback = callback
  }

  public onExtandBtnClick (callback): void {
    this.extandBtnClickCallback = callback
  }

  public init ({ nodes = [], edges = [], expandAll = false, direction = this.direction }: {
    nodes: Array<DagreDirectedChartTypes.ChartNode>;
    edges: Array<DagreDirectedChartTypes.ChartEdge>;
    expandAll: boolean;
    direction: string;
  }): void {
    this.direction = direction
    // 記住資料
    this.resetNodes({ nodes, edges })
    
    // 實際呈現的資料
    this.showData = JSON.parse(JSON.stringify(this.allData))
    if (expandAll === false) {
      // 預設第一筆為根節點
      const rootNode = this.showData.nodes[0] ? this.showData.nodes[0].id : ''
      // 全部顯示的節點
      let allShowNodes = [rootNode]
      for (const link of this.showData.edges) {
        if (link._start === rootNode) {
          allShowNodes.push(link._end)
        }
        if (link._end === rootNode) {
          allShowNodes.push(link._start)
        }
      }
      // root上下游一層的連結
      const allShowEdges = this.showData.edges.filter(d => {
        if (d._start === rootNode || d._end === rootNode) {
          return true
        }
        return false
      })
      // // root上下游一層全部的連結
      // const allShowEdges = this.showData.edges.filter(d => {
      //   if (allShowNodes.includes(d._start) && allShowNodes.includes(d._end)) {
      //     return true
      //   }
      //   return false
      // })
      this.showData.edges = allShowEdges

      this.showData.nodes = this.showData.nodes.filter(d => {
        if (allShowNodes.includes(d.id)) {
          return true
        }
        return false
      })
    }
    // _NodesMap
    this.showData._NodesMap = new Map()
    this.showData.nodes.forEach((d: ForceDirectedChartTypes.ChartNode) => {
      this.showData._NodesMap.set(d.id, d)
    })

    this.render({
      nodes: this.showData.nodes,
      edges: this.showData.edges,
      direction
    })
  }

  public resetNodes ({ nodes = [], edges = [] }: {
    nodes: Array<DagreDirectedChartTypes.ChartNode>,
    edges: Array<DagreDirectedChartTypes.ChartEdge>,
    // center?: boolean // 置中
  }): void {
    // 記住資料
    this.allData.nodes = nodes
    this.allData.edges = edges
    this.allData._NodesMap = new Map()
    nodes.forEach(d => {
      this.allData._NodesMap.set(d.id, d)
    })
  }

  public changeDirection (direction: string): void {
    
    this.direction = direction

    this.render({
      nodes: this.showData.nodes,
      edges: this.showData.edges,
      direction
    })
  }

  public toggleTopNodes (id: string): void {
    // 找到目標節點，並將該節點展開狀態反過來（開或關）
    let findAllNodeID = null
    let findShowNodeID = null
    let currentIsTopExpanded = false
    for (let i in this.allData.nodes) {
      if (this.allData.nodes[i].id === id) {
        // this.allData.nodes[i].isTopExpanded = !this.allData.nodes[i].isTopExpanded
        // findAllNode = this.allData.nodes[i]
        findAllNodeID = i
        break
      }
    }
    for (let i in this.showData.nodes) {
      if (this.showData.nodes[i].id === id) {
        // this.showData.nodes[i].isTopExpanded = !this.showData.nodes[i].isTopExpanded
        // findShowNode = this.showData.nodes[i]
        findShowNodeID = i
        break
      }
    }
    if (findAllNodeID == null || findShowNodeID == null) {
      return
    }
    currentIsTopExpanded = this.showData.nodes[findShowNodeID].isTopExpanded
    this.allData.nodes[findAllNodeID].isTopExpanded = !currentIsTopExpanded
    this.showData.nodes[findShowNodeID].isTopExpanded = !currentIsTopExpanded

    // -- 重新計算要呈現的節點data --
    if (this.showData.nodes[findShowNodeID].isTopExpanded === true) {
      let { nodes, edges } = addLoop(this.showData, this.allData, id)
      this.showData.nodes = nodes
      this.showData.edges = edges
    } else {
      // 關閉node將該node指向的node移除掉
      this.showData = JSON.parse(JSON.stringify(this.showData))
      let { nodes, edges } = deleteLoop(this.showData, this.allData, id)
      this.showData.nodes = nodes
      this.showData.edges = edges
    }
    this.showData._NodesMap = new Map()
    this.showData.nodes.forEach(d => {
      this.showData._NodesMap.set(d.id, d)
    })
    this.render({
      nodes: this.showData.nodes,
      edges: this.showData.edges,
      direction: this.direction
    })
  }

  public getChartSize (): { width: number; height: number; x: number; y: number } {
    let width = 0
    let height = 0
    let x = 0
    let y = 0
    try {
      const getBBox = this.svgGroup.node().getBBox()
      width = getBBox.width
      height = getBBox.height
      const transform = this.svgGroup.attr('transform')
      x = Number(transform.slice(transform.indexOf('(') + 1, transform.indexOf(',')))
      y = Number(transform.slice(transform.indexOf(',') + 1, transform.indexOf(')')))
    } catch (e) { console.error(e) }

    return { width, height, x, y }
  }

  // 置中
  public resize (): void {
    // reset zoom scale
    if (this.zoom && this.zoom.transform) {
      this.svg.call(this.zoom.transform, d3.zoomIdentity.scale(1));
    }
    // 取得圖表container (div)的尺寸
    let size = { width: 0, height: 0 }
    try {
      size = this.getContainerSize()
      if (!size || !size.width || !size.height || !this.chartG) {
        return
      }
      // 重設svg尺寸
      this.svg.attr('width', `${size.width}px`)
      this.svg.attr('height', `${size.height}px`)
      // 置中偏移
      const xCenterOffset = (Number(size.width) - this.chartG.graph().width) / 2
      const yCenterOffset = (Number(size.height) - this.chartG.graph().height) / 2
      this.svgGroup.attr("transform", `translate(${xCenterOffset}, ${yCenterOffset}) scale(1)`)
      // 滑鼠滾動放大縮小
      this.setZoom(xCenterOffset, yCenterOffset)
      // this.svg.on('zoom', null)
      // this.zoom = d3.zoom().on('zoom', () => {
      //   // 偏移的座標由中心點計算
      //   this.svgGroup.attr('transform', `translate(
      //                                       ${d3.event.transform.x + (xCenterOffset * d3.event.transform.k)},
      //                                       ${d3.event.transform.y + (yCenterOffset * d3.event.transform.k)}
      //                                     ) scale(
      //                                       ${d3.event.transform.k}
      //                                     )`)
        
      // })
      // this.svg.call(this.zoom)
    } catch (e) {
      console.error(e)
      return
    }
    

  }
}
