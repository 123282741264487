// @ts-nocheck
// 假資料
// import '../../mock/mediaEmotionMock.js'

require('./media_emotion.scss')

import * as d3 from 'd3'
import * as codes from '../../utils/codes.js'
import utils from '../../utils/utils'
import ChartCard from '../../components/ChartCard/ChartCard'
import EmotionProgressChart from '../../components/EmotionProgressChart/EmotionProgressChart'
import EmotionInfoCard from '../../components/EmotionInfoCard/EmotionInfoCard'
import BottomMenu from '../../components/BottomMenu/BottomMenu'
import chooseLogoSvgBase64 from '../../assets/base64/chooseLogoSvgBase64.js'
import * as MediaEmotionTypes from '../../types/charts/mediaEmotion'

// 整張圖表都會用到的設定值（會傳入component內）
// const chartConfig = {
//   cardId: (() => {
//     // 亂數長度為5的英文+數字
//     return 'choose__reputation-media__' + Math.random().toString(36).substr(2, 5)
//   })()
// }

const apiUrl = 'https://choose.blueplanet.com.tw/api/v_widget/mediaEmotion'
// const defaultQuery = {
//   'basic': {
//     'ignore_keyword': '1',
//     'keyword': '無主題',
//     'startDate':'2019-05-28 11:30',
//     'endDate':'2019-05-29 11:30'
//   },
//   'filter': null
// }

const bottomMenuList = [
  {
    label: '總覽',
    value: 'all'
  },
  {
    label: '新聞',
    value: 'news'
  },
  {
    label: 'PTT',
    value: 'ptt'
  },
  {
    label: 'FB',
    value: 'facebook'
  },
  // {
  //   label: '部落格',
  //   value: 'blog'
  // }
]

export default class MediaEmotion implements MediaEmotionTypes.IMediaEmotion {
  private dom: d3.Selection<d3.BaseType, any, any, any>
  private el: d3.Selection<d3.BaseType, any, any, any>
  private bottomMenuCallback: () => void
  private query: any // @Q@ 型別之後再補
  private chartData: any // @Q@ 型別之後再補
  private totalCount: number
  // private cardSizePixel = { // @Q@ 這張先寫死
  //   width: 650,
  //   height: 700
  // }
  private chartCard: ChartCard
  private chartSelector: d3.Selection<d3.BaseType, any, any, any>
  private emotionProgressChart: EmotionProgressChart
  private emotionInfoCard: EmotionInfoCard

  public then: (callback: (arg: any) => void) => MediaEmotionTypes.IMediaEmotion
  public error: (callback: (arg: any) => void) => MediaEmotionTypes.IMediaEmotion

  constructor (dom, options) {
    if (!dom || !options) {
      throw new Error('missing necessary parameter');
    }
    this.dom = dom
    this.el = d3.select(dom)
    this.bottomMenuCallback = function () {}
    this.query = null
    this.totalCount // 總計數量

    let cardSize = options.size == null ? utils.clone(codes.MEDIA_EMOTION_CARD_SIZE[codes.DEFAULT_CARD_SIZE_TYPE]) : codes.MEDIA_EMOTION_CARD_SIZE[options.size]
    
    // 放置卡片
    this.chartCard = new ChartCard(
      this.el,
      {
        name: 'media-emotion',
        title: '24小時即時評價監測',
        watermarkBase64: chooseLogoSvgBase64,
        tooltip: '本圖表會依據您所監測的主題，搜尋出符<br>合監測目標的資料來源，進行 24 小時即時<br>評價監測（正、負面及中立）與其露出頻<br>道排行前 5 名。',
        size: options.size || codes.DEFAULT_CARD_SIZE_TYPE,
        // style: {
        //   width: this.cardSizePixel.width,
        //   height: this.cardSizePixel.height,
        //   header: {
        //     height: 85
        //   }
        // }
        style: cardSize
    })
    this.chartSelector = this.chartCard.chartSelector()

    // 點擊開啟蛛思
    const stopBackgroundLink = options.stopBackgroundLink == null ? false : options.stopBackgroundLink
    this.chartCard.onCardClick(() => {
      if (stopBackgroundLink === true) {
        return
      }
      utils.goToChoose()
    })
    this.chartCard.onChartClick(() => {
      if (stopBackgroundLink === true) {
        return
      }
      utils.goToChoose()
    })

    // 放置圖表 EmotionProgressChart & EmotionProgressChart
    let cardContainerHtml = '\
      <div id="choose__emotion_progress_chart-box"></div>\
      <div id="choose__emotion_list-box"></div>\
      <div id="choose__bottom_menu-box" class="choose__bottom-menu-box"></div>'
    this.chartSelector.html(cardContainerHtml)
    let emotionProgressChartEl = this.chartSelector.select('#choose__emotion_progress_chart-box')
    let emotionInfoCardEl = this.chartSelector.select('#choose__emotion_list-box')
    let emotionInfoCardParams = {
      titleSet: {
        positive: '正面頻道排行',
        negative: '負面頻道排行'
      },
      size: options.size
    }
    this.emotionProgressChart = new EmotionProgressChart(emotionProgressChartEl)
    this.emotionInfoCard = new EmotionInfoCard(emotionInfoCardEl, emotionInfoCardParams)

    // 底部選單
    let bottomMenuEl = this.el.select('#choose__bottom_menu-box')
    let list = bottomMenuList.map(d => {
      return {
        name: d.label,
        label: d.label,
        icon: null,
        color: null,
        value: d.value
      }
    })
    let bottomMenu = new BottomMenu(bottomMenuEl, list, 'all')
    bottomMenu.onClick(d => {
      this.bottomMenuCallback(d.value)
      bottomMenu.active(d.name)
    })
  }

  private _doRenderText () {
    if (this.query.basic.origin === 'all' || this.query.basic.origin == null) {
      // 總計數量
      this.totalCount = this.chartData.positive.count + this.chartData.neutral.count + this.chartData.negative.count
    }
    let html = `您所監測的主題為<span class="highlight">${this.query.basic.keyword}</span>，共為您搜尋出<span class="highlight">${utils.toCurrency(this.totalCount)}</span>篇網路資料，並分析以下結果：`
    this.chartCard.setSubtitle(html)
  }

  private _doRenderChart () {
    this.emotionProgressChart.render(this.chartData)
    this.emotionInfoCard.render(this.chartData)
  }

  // 取得api資料
  public fetchData (query): MediaEmotionTypes.IMediaEmotion {
    const defaultDateTimeRange = utils.get24HourDateTimeRange()
    let successCallback: (arg: any) => void = function () {}
    let errorCallback: (arg: any) => void = function () {}
    this.query = {
      basic: {
        // startDate: (query.startDate == null || query.endDate == null) ? defaultDateTimeRange.startDate : query.startDate,
        // endDate: (query.startDate == null || query.endDate == null) ? defaultDateTimeRange.endDate : query.endDate,
        startDate: defaultDateTimeRange.startDate,
        endDate: defaultDateTimeRange.endDate,
        keyword: query.keyword == null ? '' : query.keyword,
        origin: query.origin,
        'ignore_keyword': !query.keyword ? '1' : '0'
      },
      token: query.token,
      widgetLog: {
        referrer: utils.getPageHref()
      }
    }

    this.then = callback => {
      successCallback = callback
      return this
    }
    this.error = callback => {
      errorCallback = callback
      return this
    }

    setTimeout(() => {
      utils.ajax({
        type: 'POST',
        url: apiUrl,
        data: this.query,
        success (data) {
          successCallback(data)
        },
        error (data) {
          errorCallback(data)
        }
      })
    })

    return this
  }

  public render (data): MediaEmotionTypes.IMediaEmotion {
    // 將資料記住
    this.chartData = data

    // 先顯示文字
    this._doRenderText()

    // 觸發顯示圖表動畫
    utils.addScrollTrigger(this.dom, () => this._doRenderChart())

    return this
  }

  public onBottomMenuSelect (callback): void {
    this.bottomMenuCallback = callback
  }
}
