// @ts-nocheck
require('./tooltip.scss')

// import { select, selectAll, update, remove, merge, data, attr, classed, style, html } from 'd3-selection'
// const d3 = {
//   select
// }
import * as d3 from 'd3'

function getContainerSize (el) {
  try {
    return el.node().getBoundingClientRect()
  } catch (e) {
    console.error(e)
    return null
  }
}

export default class Tooltip {
  private containerEl
  private x
  private y
  private insideBoxMode = false
  private html
  private tooltip
  // insideBoxMode為true時設定邊界座標
  private limitBoundary = {
    minX: 0,
    maxX: 0,
    minY: 0,
    minY: 0
  }

  constructor (containerEl, content, x, y, insideBoxMode = false) {
    
    // console.log({containerEl, content, x, y})
    // console.log(containerEl.node().getBoundingClientRect())
    this.containerEl = containerEl
    this.remove()
    this.containerEl.classed('choose__tooltip-container', true)

    
    let html = '<div>{{html:content}}</div>'
    this.html = html.replace('{{html:content}}', content != null ? content : '')

    this.tooltip = this.containerEl
      .append('div')
      .classed('choose__tooltip', true)
    if (x != null && y != null) {
      this.x = x
      this.y = y
      this.setFixedTooltip(this.tooltip)
    } else {
      this.setRelatedTooltip(this.tooltip)
    }

    // insideBoxMode設定邊界座標
    if (insideBoxMode != null) {
      this.insideBoxMode = insideBoxMode
      if (this.insideBoxMode === true) {
        const tooltipSize = getContainerSize(this.tooltip)
        const containerSize = getContainerSize(this.containerEl)
        this.limitBoundary = {
          minX: containerSize.x,
          maxX: containerSize.x + containerSize.width - tooltipSize.width,
          minY: containerSize.y,
          maxY: containerSize.y + containerSize.height - tooltipSize.height
        }
        // 修正座標
        if (x != null && y != null) {
          this.move(x, y)
        }
      }
    }
    
  }

  private getInsideBoxBoordinate (x, y) {

  }

  private setFixedTooltip (el) {
    el.style('position', 'fixed')
      .style('z-index', 10000)
      .style('left', this.x + 'px')
      .style('top', this.y + 'px')
      .html(this.html)
  }

  private setRelatedTooltip (el) {
    el.style('position', 'absolute') // 如無設定座標使用absolute
      .style('z-index', 10000)
      .style('top', '20px')
      .style('left', '30px')
      .html(this.html)
  }
  
  public move (x, y) {
    if (this.insideBoxMode === true) {
      if (x > this.limitBoundary.maxX) {
        x = this.limitBoundary.maxX
      }
      if (y > this.limitBoundary.maxY) {
        y = this.limitBoundary.maxY
      }
    }
    // d3.select('.choose__tooltip-container .choose__tooltip').style('left', x + 'px').style('top', y + 'px')
    this.tooltip.style('left', x + 'px').style('top', y + 'px')
    // d3.select('#choose__tooltip').style('transform', 'translate(' + x + ',' + y + ')')
  }
  public remove () {
    let el = d3.selectAll('.choose__tooltip-container .choose__tooltip').html('')
    el.remove()
    // this.tooltip.remove()
    this.containerEl.classed('choose__tooltip-container', false)
  }

}
