require('./reaction_bar_chart.scss')

import * as d3 from 'd3'
import ReactionTooltip from '../ReactionTooltip/ReactionTooltip.js'
import ReactionIcon from '../ReactionIcon/ReactionIcon.js'

const padding = { 
  top: 10,
  right: 20,
  bottom: 25,
  left: 30
} // 內距

const rectWidth = 8 // 長條圖的寬度

export default class ReactionsBarChart {
  constructor (el, params) {

    this.width = params.options.width // svg寬度
    this.height = params.options.height // svg寬度
    this.axisWidth = this.width - padding.left - padding.right
    this.axisHeight = this.height - padding.top - padding.bottom // 圖表高度為svg高度扣掉內距
    this.rectStep // 各別長條圖的距離
    this.maxValue
    this.sumValue
    this.maxPercent // 最大資料佔比
    this.middlePercent // 最大資料佔比 / 2

    this.xScale
    this.yScale
    this.componentEl = el
      .append('div')
      .classed('choose__reaction-bar', true)
      .style("width", `${this.width}px`)
      .style("height", `${this.height}px`)
    this.svg = this.componentEl
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
    this.xAxisEl = this.svg.append('g').classed("choose__reaction-bar__xAxis",true)
    this.yAxisEl = this.svg.append('g').classed("choose__reaction-bar__yAxis",true)
    this.iconContainerEl = this.svg.append('g').classed("choose__reaction-bar__iconContainer",true)
    this.barContainer = this.componentEl.append('div').classed("choose__reaction-bar__bar-container",true)
  }

  _setXAxisEl (xAxisEl) {
    var xAxis = d3.axisBottom()
      .scale(this.xScale)
      .ticks(5)
      .tickFormat(function(d){
        return d // 不顯示刻度，另外加上圖示
      })
      .tickSize(0, 0)
    
    xAxisEl
      .attr("transform","translate("+padding.left+","+(padding.top + this.axisHeight)+")")
      .call(xAxis)
  }

  _setYAxisEl (yAxisEl) {
    var yAxis = d3.axisLeft()
      .scale(this.yScale)
      // .ticks(7)
      .tickFormat(function(d){
        return d.toFixed(0) + '%'
      })
      .tickSize(0, -this.axisWidth)  
      .tickValues([this.middlePercent, this.maxPercent])
    
    yAxisEl
      .attr("transform","translate("+padding.left+","+padding.top+")")
      .call(yAxis)
  }

  _setReactionIcon (iconContainerEl, data) {
    iconContainerEl.html('')
    let update = iconContainerEl
      .selectAll('g')
      .data(data)
    let enter = update.enter().append('g')
    let merge = enter.merge(update)
    enter.each((d, i, all) => {
      let iconEl = d3.select(all[i])
      new ReactionIcon(iconEl, d.name, {
        // x: padding.left + this.xScale(d.name) - 9,
        // y: this.height - padding.bottom,
        width: 18,
        height: 18
      })
    })
    merge
      .attr('transform', d => {
        return `translate(
          ${padding.left + this.xScale(d.name) - 9},
          ${this.height - padding.bottom + 5})`
      })
    
    this._setTooltipEvent(merge)
    
  }

  _setRect (barContainer, dataSet) {
    var updateRect = barContainer.selectAll("div").data(dataSet)
    var enterRect = updateRect.enter(dataSet)
    // debugger
    // enterRect.each((d, i, all) => {
    //   debugger
    // })
    var mergeRect = enterRect
      .append("div")
      .classed('choose__reaction-bar__bar-item', true)
      .merge(updateRect)
    var exitRect = updateRect.exit()
    exitRect.remove()
    mergeRect
      .style('left', (d,i) => {
        // return `${padding.left + (i + 1) * this.rectStep}px`
        return `${padding.left + this.xScale(d.name) - (rectWidth / 2)}px`
      })
      .style('bottom', d => {
        return `${padding.bottom}px`
      })
      .transition()
      .duration(1000)
      .tween('move', (d, i, all) => {
        let selection = d3.select(all[i])
        let height = this.axisHeight - this.yScale(d.percent)
        return t => {
          selection
            // .attr("fill", "steelblue")
            // .attr("x", (d,i) => {
            //   return padding.left + (i + 1) * this.rectStep
            // })
            // .attr("y", d => {
            //   return this.height - padding.bottom - (this.axisHeight - this.yScale(d.percent))
            // })
            .style("width", `${rectWidth}px`)
            .style("height", () => {
              return `${height * t}px`
            })
        }
      })

    this._setTooltipEvent(mergeRect)
    // let sumValue = d3.sum(dataSet, d => d.value)
    
    
  }
  
  _setTooltipEvent (targetEl) {
    let tooltip
    targetEl.on('mouseover', (d, i, all) => {
      // // let x = d3.event.clientX
      // // let y = d3.event.clientY
      // // let x = d.parent.x0 + ((d.parent.x1 - d.parent.x0) / 2)
      // // let y = d.parent.y0 + ((d.parent.y1 = d.parent.y0) / 2)
      // let thisWidth = d3.select(this).select('rect').attr('width')
      // let thisHeight = d3.select(this).select('rect').attr('height')
      // let x = d.x0 + (thisWidth / 2) + 50
      // let y = d.y0 + (thisHeight / 2) - 20
      let x = d3.event.clientX + 20
      let y = d3.event.clientY
      let content = {
        reaction: d.name,
        count: d.value,
        percent: d.percent
      }
      tooltip = new ReactionTooltip(this.componentEl, content, x, y)
    })
    .on('mousemove', d => {
      if(tooltip != null) {
        let x = d3.event.clientX + 20
        let y = d3.event.clientY
        tooltip.move(x, y)
      }
    })
    .on('mouseout', d => {
      if(tooltip != null) {
        tooltip.remove()
      }
    })
  }

  render (data) {
    // 最大值
    this.maxValue = d3.max(data, d => d.value)
    // 總合
    this.sumValue = d3.sum(data, d => d.value)
    // 最大資料佔比
    this.maxPercent = (this.maxValue / this.sumValue) * 100
    // 最大資料佔比 / 2
    this.middlePercent = this.maxPercent / 2

    this.rectStep = this.axisWidth / (data.length + 1)
    // 增加百分比資料
    const dataSet = data
      .map(d => {
        d.percent = (d.value / this.sumValue) * 100
        return d
      })
      .sort((a, b) => b.value - a.value)
    
    const nameList = dataSet.map(d => d.name)
  
    this.xScale = d3.scalePoint()
      // .domain([0, this.maxValue])
      .domain(nameList)
      .range([0, this.axisWidth])
      .padding(1)

    this.yScale = d3.scaleLinear()
      .domain([this.maxPercent, 0])
      // .domain([1,0])
      .range([0, this.axisHeight])

    // -- 繪製刻度 --
    this._setXAxisEl(this.xAxisEl)
    this._setYAxisEl(this.yAxisEl)

    // -- 繪製表情icon --
    this._setReactionIcon(this.iconContainerEl, dataSet)

    // -- 繪製長條圖 --
    this._setRect(this.barContainer, dataSet)
    
    
    // -- 繪製數字 --
    // var updateText = this.svg.selectAll("text").data(dataSet)
    // var enterText = updateText.enter()
    // var exitText = updateText.exit()
  
    // this._fillText(updateText)
    // this._fillText(enterText.append("text"))
    // exitText.remove()
  }

}