require('./score_stars.scss')

const svgContent = {
  star:
    '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
      <g id="Artboard" transform="translate(-337.000000, -2264.000000)" fill="#F5A623">\
        <path d="M344.588039,2264.55658 L342.513089,2268.69357 L337.870668,2269.35911 C337.038146,2269.47784 336.704502,2270.48709 337.308239,2271.06515 L340.666924,2274.2835 L339.872533,2278.82981 C339.729543,2279.65159 340.609728,2280.26713 341.346923,2279.88281 L345.5,2277.7362 L349.653077,2279.88281 C350.390272,2280.26401 351.270457,2279.65159 351.127467,2278.82981 L350.333076,2274.2835 L353.691761,2271.06515 C354.295498,2270.48709 353.961854,2269.47784 353.129332,2269.35911 L348.486911,2268.69357 L346.411961,2264.55658 C346.040186,2263.81917 344.962992,2263.80979 344.588039,2264.55658 Z" id="star-copy-3"></path>\
      </g>\
    </g>',
  half:
    '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
      <g id="Artboard" transform="translate(-357.000000, -2264.000000)" fill="#F5A623" fill-rule="nonzero">\
        <path d="M373.12934,2269.35969 L368.487061,2268.69375 L366.412514,2264.55656 C366.226024,2264.18687 365.862241,2264 365.498458,2264 C365.136895,2264 364.775649,2264.18437 364.588524,2264.55656 L362.513343,2268.69344 L357.870747,2269.35875 C357.0382,2269.4775 356.704548,2270.48656 357.308104,2271.06469 L360.666517,2274.28344 L359.871713,2278.82969 C359.758803,2279.47906 360.284973,2280 360.874257,2280 C361.030617,2280 361.191418,2279.96344 361.345875,2279.88281 L365.499092,2277.73656 L369.651992,2279.88344 C369.806132,2279.96313 369.966615,2279.99937 370.122341,2279.99937 C370.71226,2279.99937 371.23938,2279.48031 371.126471,2278.83063 L370.332618,2274.28406 L373.691666,2271.06594 C374.295539,2270.48781 373.961886,2269.47844 373.12934,2269.35969 Z M369.268228,2273.20969 L368.693533,2273.76031 L368.829277,2274.53781 L369.448375,2278.08313 L366.209214,2276.40844 L365.499409,2276.04156 L365.500361,2266.12937 L367.118831,2269.35719 L367.473416,2270.06437 L368.266635,2270.17812 L371.889559,2270.69781 L369.268228,2273.20969 L369.268228,2273.20969 Z" id="star-half-alt"></path>\
      </g>\
    </g>',
  empty:
    '<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
      <g id="Artboard" transform="translate(-377.000000, -2264.000000)" fill="#F5A623" fill-rule="nonzero">\
        <path d="M392.764415,2269.35911 L388.227026,2268.69357 L386.199021,2264.55658 C385.835657,2263.81917 384.782833,2263.80979 384.416364,2264.55658 L382.388359,2268.69357 L377.850969,2269.35911 C377.037283,2269.47784 376.711187,2270.48709 377.301265,2271.06515 L380.583962,2274.2835 L379.807544,2278.82981 C379.667789,2279.65159 380.52806,2280.26713 381.248577,2279.88281 L385.307692,2277.7362 L389.366808,2279.88281 C390.087324,2280.26401 390.947596,2279.65159 390.807841,2278.82981 L390.031422,2274.2835 L393.31412,2271.06515 C393.904198,2270.48709 393.578102,2269.47784 392.764415,2269.35911 L392.764415,2269.35911 Z M388.432,2273.75856 L389.168045,2278.08303 L385.307692,2276.04266 L381.44734,2278.08303 L382.183384,2273.75856 L379.059077,2270.69644 L383.375963,2270.06527 L385.307692,2266.12826 L387.239421,2270.06527 L391.556308,2270.69644 L388.432,2273.75856 Z" id="Shape"></path>\
      </g>\
    </g>'
}

export default class ScoreStars {
  constructor (el, score) {
    this.el = el
    
    this._setStarImage(score)
    
  }
  _setStarImage (score) {
    const star = Math.floor(score) // 星星數
    const half = Math.round(score % 1) // 半顆星星數（小數點四捨五入）
    const empty = 5 - star - half
    // let content = svgContent[emotion]
    
    for (let i = 1; i <= star; i++) {
      this.el.append('svg')
        .call(this._setElementAttr)
        .html(svgContent.star)
    }
    for (let i = 1; i <= half; i++) {
      this.el.append('svg')
        .call(this._setElementAttr)
        .html(svgContent.half)
    }
    for (let i = 1; i <= empty; i++) {
      this.el.append('svg')
        .call(this._setElementAttr)
        .html(svgContent.empty)
    }
  }
  _setElementAttr (el) {
    el
      .classed('choose__score-stars__star', true)
      .attr('width', 17)
      .attr('height', 16)
      .attr('viewBox', '0 0 17 16')
  }
  remove () {
    this.el.html('')
  }
  change (score) {
    this.remove()
    this._setStarImage(score)
  }
}