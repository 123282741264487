require('./emotion_item_list.scss')

import * as d3 from 'd3'

export default class EmotionItemList {
  constructor (el) {
    el.append('div')
      .classed('choose__emotion-item-list', true)
      .html(`
        <div class="choose__emotion-item-list__item">
          <div class="choose__emotion-item-list__dot choose--negative"></div>
          <div class="choose__emotion-item-list__text">負面</div>
        </div>
        <div class="choose__emotion-item-list__item">
          <div class="choose__emotion-item-list__dot choose--neutral"></div>
          <div class="choose__emotion-item-list__text">中立</div>
        </div>
        <div class="choose__emotion-item-list__item">
          <div class="choose__emotion-item-list__dot choose--positive"></div>
          <div class="choose__emotion-item-list__text">正面</div>
        </div>
      `)
  }
}
